import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './pages/_app';
import TagManager from 'react-gtm-module';

import './scss/index.scss';

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID
    });
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
