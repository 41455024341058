import React, {useMemo} from "react";
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {useFormManager} from "../../../util/form-manager";

import './styles.scss';


const FormProgress = ({steps}) => {

    const {currentStep} = useFormManager();

    const location = useLocation();

    const formComplete = useMemo(() => {
        return location.pathname.endsWith('complete');
    }, [location.pathname]);

    return (
        <div className="form-progress">
            {
                steps.map(s => {
                    const active = formComplete ? false : s.step === currentStep,
                          next = formComplete ? false : currentStep + 1 === s.step,
                          complete = formComplete || currentStep > s.step;

                    return (
                        <div key={`s${s.step}`}
                             className={`form-progress-step ${active ? 'active' : ''} ${next ? 'next' : ''} ${complete ? 'complete' : ''}`}>
                            <div className="form-progress-subtitle">
                                {active && 'This step'}
                                {next && 'Next step'}
                                {complete && 'Complete'}
                            </div>
                            <div className="form-progress-label">
                                {s.label} {complete && <FontAwesomeIcon icon={faCheckCircle}/>}
                            </div>
                        </div>
                    )
                })
            }

        </div>
    );
};

FormProgress.propTypes = {
    steps: PropTypes.array.isRequired
};

export default FormProgress;
