import React from "react";
import {Link} from "react-router-dom";

import "./styles.scss";

const Header = () => {
    return (
        <div className="header">
            <div className="header-logo">
                <Link to={'/'}><img src="/rd-petroleum-logo.png" alt="RD Petroleum" /></Link>
            </div>
            <div className="header-cards">
                <img src="/rd-petroleum-cards.png" alt="RD Petroluem Cards" />
            </div>
        </div>
    );
};

export default Header;
