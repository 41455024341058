import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import FormField from "../../../components/forms/FormField";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormButton from "../../../components/forms/FormButton";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import axios from "axios";
import { debounce } from "lodash";

const AccountSchema = yup.object().shape({
    legal_entity_name: yup.string().required("Full legal name of entity is required"),
    email_address: yup.string().email("Must be a valid email address").required("Email address is required"),

    // physical_address: yup.string().required("Physical address is required"),
    // postal_address: yup.string().required("Postal address is required"),
    phone: yup.string(),
    mobile: yup.string().required("Mobile phone is required"),

    authorised_person_first_name: yup.string().required("First name is required"),
    authorised_person_last_name: yup.string().required("Last name is required"),
    authorised_person_email_address: yup.string().email("Must be a valid email address").required("Email address is required"),

    independent_contact_name: yup.string().required("Contact person name is required"),
    independent_contact_address: yup.string().required("Contact person address is required"),
    independent_contact_phone: yup.string().required("Contact person phone is required"),
    nzbn_number: yup.string().required(),
    nzbn_trading_name: yup.string(),
    nzbn_business_address: yup.string(),
    nzbn_business_number: yup.string(),
});

const Account = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: AccountSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [tradingName, setTradingName] = useState('');
    const [address, setAddress] = useState('');
    const [companyNum, setCompanyNum] = useState('');
    const [nzbnError, setNzbnError] = useState('');
    const [disableNext, setDisableNext] = useState(true);

    const onSubmit = async data => {
        const manipulate = (data) => {
            const updated = {...data};
            updated.authorised_person_full_name = `${updated.authorised_person_first_name} ${updated.authorised_person_last_name}`;
            if(!updated.physical_address) {
                const combinedPhysicalAddress = [updated.physical_street, updated.physical_suburb, updated.physical_city, updated.physical_postcode].filter(Boolean).join(', ');
                updated.physical_address = combinedPhysicalAddress;
            }
            if(!updated.postal_address) {
                const combinedPostalAddress = [updated.postal_street, updated.postal_suburb, updated.postal_city, updated.postal_postcode].filter(Boolean).join(', ');
                updated.postal_address = combinedPostalAddress;
            }

            return updated;
        };
        submitFormStep({data, step: 1, url: "/bulk-business/guarantors", setError, manipulate});
    };

    useEffect(() => {
        runScrollToTop();
        updateFormStep(1);
        loadSavedFormValues(setValue);
    }, [setValue, updateFormStep, loadSavedFormValues]);

    useEffect(() => {
        showNZBNField()
    }, []);

    const showNZBNField = () => {
        let obj = loadSavedFormValues(setValue);
        if('nzbn_trading_name' in obj && obj.nzbn_trading_name !== '') {
            setTradingName(obj.nzbn_trading_name)
        }
        if('nzbn_business_address' in obj && obj.nzbn_business_address !== '') {
            setAddress(obj.nzbn_business_address)
        }
        if('nzbn_business_number' in obj && obj.nzbn_business_number !== '') {
            setCompanyNum(obj.nzbn_business_number)
            setDisableNext(false);
        }
    }

    const getBusinessInfo = async (value) => {
        const requestHeaders = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'X-API-KEY': process.env.REACT_APP_API_KEY,
            'Accept': 'application/x.rdp.v1+json'
        };
        if(value !== "") {
            return new Promise((resolve, reject) => {
                axios.request({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_BASE_URL}/nzbn/${value}/business`,
                    headers: requestHeaders,
                })
                .then(res => {
                    if(res.data.status === "400") {
                        setNzbnError('A company could not be found with the provided NZBN number. Please try again or contact RD Petroleum on 0800 44 00 14 for help');
                        setTradingName('');
                        setAddress('');
                        setCompanyNum('');
                        setDisableNext(true);
                    } else {
                        setTradingName(res.data.entityName);
                        setAddress(res.data.addresses.addressList[0].address1+', '+res.data.addresses.addressList[0].address2+', '+res.data.addresses.addressList[0].address3);
                        setCompanyNum(res.data.sourceRegisterUniqueIdentifier);
                        setNzbnError('');
                        setDisableNext(false);
                    }
                })
                .catch(err => reject(err));
            });
        }
    }

    const debouncedGetBusinessInfo = useCallback(debounce((value) => {
        getBusinessInfo(value);
      }, 1000), []);

    const handleChange = (e) => {
        const { value } = e.target;
        debouncedGetBusinessInfo(value);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>

            <div className="section">
                <h2>Business Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="Full Legal Name of Entity"
                            name="legal_entity_name"
                            ref={register}
                            id="legal_entity_name"
                            type="text"
                            required={true}
                            error={errors.legal_entity_name} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="email_address" ref={register} id="email_address" type="email" required={true}
                                   error={errors.email_address}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Physical Address"
                            name="physical_address"
                            register={register}
                            id="physical_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors}
                            applicantOrDirector={true}
                            extraFieldsName={"physical"}
                            />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="postal_address"
                            register={register}
                            id="postal_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors} 
                            applicantOrDirector={true}
                            extraFieldsName={"postal"}
                            />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Mobile Number" name="mobile" ref={register} id="mobile" type="text" required={true} error={errors.mobile}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="phone" ref={register} id="phone" type="text" required={false} error={errors.phone}/>
                    </div>
                </div>
            </div>


            <div className="section">
                <h2>Authorised Representative</h2>

                <Alert variant="warning">
                    <p>Please note, this person must be authorised to sign on behalf of the company / trust.</p>
                    <p>
                        On completion of this form, applicants will be sent an email inviting them to
                        sign the form electronically. When signing the form, all applicants will need to upload
                        a copy of their passport or driving license.
                    </p>
                </Alert>
                <div className="row">
                    <div className="col-md-6">
                        <FormField label="First Name" name="authorised_person_first_name" ref={register} id="authorised_person_first_name" type="text" required={true}
                                   error={errors.authorised_person_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Last Name" name="authorised_person_last_name" ref={register} id="authorised_person_last_name" type="text" required={true}
                                   error={errors.authorised_person_last_name}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="authorised_person_email_address" ref={register} id="authorised_person_email_address" type="email" required={true}
                                   error={errors.authorised_person_email_address}/>
                    </div>
                </div>
            </div>



            <div className="section">
                <h2>Accountant Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="accountant_name" ref={register} id="accountant_name" type="text" error={errors.accountant_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Business Name" name="accountant_business_name" ref={register} id="accountant_business_name" type="text"
                                   error={errors.accountant_business_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Business Postal Address"
                            name="accountant_postal_address"
                            register={register}
                            id="accountant_postal_address"
                            setValue={setValue}
                            watch={watch}
                            error={errors} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="accountant_phone" ref={register} id="accountant_phone" type="text"
                                   error={errors.accountant_phone}/>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Independent Contact Person</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="independent_contact_name" ref={register} id="independent_contact_name" type="text" required={true}
                                   error={errors.independent_contact_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone" name="independent_contact_phone" ref={register} id="independent_contact_phone" type="text" required={true}
                                   error={errors.independent_contact_phone}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="independent_contact_address"
                            register={register}
                            id="independent_contact_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={!!errors.independent_contact_address} />
                    </div>
                </div>

            </div>

            <div className="section">
                <h2>NZBN Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId='nzbn_number'>
                            <Form.Label>{'NZBN'} <span className="form-required">*</span></Form.Label>
                            <Form.Control
                                name={'nzbn_number'}
                                type={'text'}
                                ref={register}
                                onChange={handleChange}
                                isInvalid={!!errors.nzbn_number}
                            />
                            {
                                !!errors.nzbn_number &&
                                <Form.Control.Feedback type="invalid">
                                    {errors.nzbn_number.message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                    {tradingName !== '' ?
                        <div className="col-md-6">
                            <Form.Group controlId='nzbn_trading_name'>
                                <Form.Label>{'Business Name'}</Form.Label>
                                <Form.Control
                                        name={'nzbn_trading_name'}
                                        type={'text'}
                                        disabled
                                        ref={register}
                                        value={tradingName}
                                        isInvalid={!!errors.nzbn_trading_name}
                                />
                                {
                                    !!errors.nzbn_trading_name &&
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nzbn_trading_name.message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>
                            : ''
                        }
                        {address !== '' ?
                            <div className="col-md-6">
                                <Form.Group controlId='nzbn_business_address'>
                                    <Form.Label>{'Business Address'}</Form.Label>
                                    <Form.Control
                                            name={'nzbn_business_address'}
                                            type={'text'}
                                            disabled
                                            ref={register}
                                            value={address}
                                            isInvalid={!!errors.nzbn_business_address}
                                    />
                                    {
                                        !!errors.nzbn_business_address &&
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nzbn_business_address.message}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </div>
                            : ''
                        }
                        {companyNum !== '' ?
                            <div className="col-md-6">
                                <Form.Group controlId='nzbn_business_number'>
                                    <Form.Label>{'Business Number'}</Form.Label>
                                    <Form.Control
                                            name={'nzbn_business_number'}
                                            type={'text'}
                                            disabled
                                            ref={register}
                                            value={companyNum}
                                            isInvalid={!!errors.nzbn_business_number}
                                    />
                                    {
                                        !!errors.nzbn_business_number &&
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nzbn_business_number.message}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </div>
                            : ''
                        }
                        <div className="col-12"><div className="d-block invalid-feedback">{nzbnError}</div></div>
                </div>
            </div>


            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/bulk-business" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit" disabled={disableNext}>Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Account;
