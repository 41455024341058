import React from "react";
import {useFormManager} from "../../../util/form-manager";
import {useEffectOnce} from "react-use";

const Complete = () => {

    const {completeForm} = useFormManager();

    useEffectOnce(() => {
        completeForm();
    });

    return (
        <>
            <div className="section">
                <h1>Application Complete</h1>
                <p>
                    Thank you, your application has been submitted.
                </p>
                <p>
                    We will email the applicants and any appropriate guarantors and witnesses a document to sign
                    electronically with Docusign. Once all parties
                    have signed
                    we will process your application.
                </p>
                <p>
                    If you have any questions, please contact us on <a href="tel:0800 44 00 14">0800 44 00
                    14</a>.
                </p>
                <p>
                    <a href="https://www.rdp.co.nz">RD Petroleum homepage</a>
                </p>
            </div>
        </>
    );
};

export default Complete;
