import React from "react";
import PropTypes from "prop-types";
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons/faCircleNotch";

import './styles.scss';

const FormButton = props => (
    <Button
        type={props.type}
        block={props.block}
        variant={props.variant}
        disabled={props.disabled || !!props.loading}
        className={props.className + (props.loading ? ' btn-loading' : '')}
        onClick={props.onClick}>
        <span className="original-text">{props.children}</span>
        <span className="loading-icon"><FontAwesomeIcon icon={faCircleNotch} spin/></span>
    </Button>
);

FormButton.propTypes = {
    type: PropTypes.string,
    variant: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func
};

FormButton.defaultProps = {
    type: 'button',
    variant: 'secondary',
    block: false,
    loading: false,
    className: '',
    disabled: false
};

export default FormButton;
