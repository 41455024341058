import React, {useCallback, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import TermsModal from "../../../components/modals/TermsModal";
import get from "lodash/get";
import FormField from "../../../components/forms/FormField";
import FormButton from "../../../components/forms/FormButton";

const SubmitSchema = yup.object().shape({
    referral_source: yup.string().required("You must specify how you heard about us"),
    referral_source_sales_rep_name: yup.string(),
    referral_source_other_details: yup.string()
        .when("referral_source", (referral_source, schema) => referral_source === 'Other' ? schema.required("Please provide details about how you heard about us") : schema),
    accept_terms: yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

const Submit = () => {
    const {register, handleSubmit, errors, setValue, setError} = useForm({validationSchema: SubmitSchema});
    const {submitFormStep, updateFormStep, loadSavedFormValues, submitFormStepLoading} = useFormManager();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [referralSource, setReferralSource] = useState(null);

    const acceptTerms = useCallback(() => {
        setValue("accept_terms", true);
    }, [setValue]);

    const onSubmit = async data => {
        const manipulate = (data) => {
            if (data.referral_source === 'CHNZ') {
                data.referral_source = 'Other';
                data.referral_source_other_details = 'CHNZ';
            }
            return data;
        };

        submitFormStep({data, step: 4, url: "/home-heating/complete", setError, manipulate});
    };

    useEffect(() => {
        updateFormStep(4, "/home-heating");
        const values = loadSavedFormValues(setValue);
        setReferralSource(get(values, "referral_source", null));
    }, [loadSavedFormValues, register, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="section">
                <h2>How did you hear about us?</h2>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group>
                            {
                                [
                                    "CHNZ",
                                    "Word of mouth",
                                    "RD Petroleum website",
                                    "Sales representative",
                                    "Newspaper advertising",
                                    "Printed flier",
                                    "Radio",
                                    "Other"
                                ].map((item, i) => (
                                    <Form.Check
                                        key={`referral_source_${i}`}
                                        name="referral_source"
                                        id={`referral_source_${i}`}
                                        isInvalid={!!errors.referral_source}
                                        feedback={i === 6 && !!errors.referral_source ? errors.referral_source.message : ""}
                                        type="radio"
                                        ref={register}
                                        value={item}
                                        label={item}
                                        onClick={(e) => setReferralSource(e.target.value)}
                                        custom/>
                                ))
                            }
                        </Form.Group>
                    </div>
                </div>

                {
                    referralSource === 'Other' &&
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <FormField label="Details"
                                           name="referral_source_other_details"
                                           ref={register}
                                           id="referral_source_other_details"
                                           error={errors.referral_source_other_details}
                                           type="text" />
                            </div>
                        </div>
                    </>
                }
                {
                    referralSource === 'Sales representative' &&
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-3">If you know the name of the sales representative that helped you,
                                    please enter it here.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <FormField label="Sales Representative" name="referral_source_sales_rep_name" ref={register} id="referral_source_sales_rep_name" type="text"/>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="section">
                <h2>Terms and Conditions</h2>
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            To complete your application, you must read and accept
                            the <span className="link" onClick={() => setTermsModalOpen(true)}>RD Petroleum Terms &amp; Conditions</span>
                        </p>
                        <Form.Group controlId="accept_terms">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="accept_terms"
                                ref={register}
                                label="I have read and accept the terms and conditions"
                                feedback={!!errors.accept_terms ? errors.accept_terms.message : ""}
                                isInvalid={!!errors.accept_terms}/>
                        </Form.Group>
                    </div>
                </div>
                <TermsModal type="home-heating" show={termsModalOpen} handleClose={() => setTermsModalOpen(false)} acceptTerms={acceptTerms}/>
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button as={Link} to="/home-heating/payment" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Submit
                            Application</FormButton>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Submit;
