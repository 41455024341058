import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Account from "./Account";
import References from "./References";
import Fuelcard from "./Fuelcard";
import DirectDebit from "./DirectDebit";
import Delivery from "./Delivery";
import Submit from "./Submit";
import Complete from "./Complete";
import Start from "./Start";
import FormProgress from "../../components/forms/FormProgress";
import {ProvideFormManager} from "../../util/form-manager";

import "./styles.scss";

const BulkIndividualPage = () => {

    const route = useRouteMatch();

    const steps = [
        {step: 1, label: "Account"},
        {step: 2, label: "References"},
        {step: 3, label: "Delivery"},
        {step: 4, label: "Fuelcard"},
        {step: 5, label: "Direct Debit"},
        {step: 6, label: "Submit"}
    ];

    return (
        <ProvideFormManager formID="bulk-individual">

            <FormProgress steps={steps} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Switch>
                            <Route path={`${route.path}/account`}>
                                <Account />
                            </Route>
                            <Route path={`${route.path}/references`}>
                                <References />
                            </Route>
                            <Route path={`${route.path}/delivery`}>
                                <Delivery />
                            </Route>
                            <Route path={`${route.path}/fuelcard`}>
                                <Fuelcard />
                            </Route>
                            <Route path={`${route.path}/submit`}>
                                <Submit />
                            </Route>
                            <Route path={`${route.path}/directdebit`}>
                                <DirectDebit />
                            </Route>
                            <Route path={`${route.path}/complete`}>
                                <Complete />
                            </Route>
                            <Route path={`${route.path}`}>
                                <Start />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </ProvideFormManager>
    );
};

export default BulkIndividualPage;
