import NewIndividualPage from './pages/new-individual';
import NewIndividualAviationPage from './pages/new-individual-aviation';
import Error404 from './pages/error404';
import IndexPage from "./pages/index";
import NewBusinessPage from "./pages/new-business";
import NewBusinessAviationPage from "./pages/new-business-aviation";
import UpdateFuelcardPage from "./pages/update-fuelcard";
import NewFuelcardPage from "./pages/new-fuelcard";
import RetrievePage from "./pages/retrieve";
import HomeHeatingPage from "./pages/home-heating";
import BulkIndividualPage from "./pages/bulk-individual";
import BulkBusinessPage from "./pages/bulk-business";

export const routes = [
    {
        path: "/",
        exact: true,
        component: IndexPage,
    },
    {
        path: "/bulk-individual",
        component: BulkIndividualPage,
    },
    {
        path: "/bulk-business",
        component: BulkBusinessPage,
    },
    {
        path: "/home-heating",
        component: HomeHeatingPage,
    },
    {
        path: "/individual",
        component: NewIndividualPage,
    },
    {
        path: "/individual-aviation",
        component: NewIndividualAviationPage,
    },
    {
        path: "/business",
        component: NewBusinessPage,
    },
    {
        path: "/business-aviation",
        component: NewBusinessAviationPage,
    },
    {
        path: "/update-fuelcard",
        component: UpdateFuelcardPage,
    },
    {
        path: "/new-fuelcard",
        component: NewFuelcardPage,
    },
    {
        path: "/retrieve/:formID",
        component: RetrievePage,
    },
    {
        component: Error404
    }
];
