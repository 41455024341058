import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './styles.scss';

const IndexButtonExternal = ({title, url, icon, icon2, icon2Styles}) => {
    return (
        <Link to={url} className="index-button" target="_blank">
            <div className={`index-button-icon ${icon2 ? 'index-button-double-icon' : ''}`}>
                <div className="index-button-icon-1">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                {
                    icon2 &&
                    <div className="index-button-icon-2" style={icon2Styles}>
                        <FontAwesomeIcon icon={icon2}/>
                    </div>
                }
            </div>
            <div className="index-button-title">
                {title}
            </div>
        </Link>
    );
};

IndexButtonExternal.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    icon2: PropTypes.object,
    icon2Styles: PropTypes.object,
    newTab: PropTypes.bool
};

export default IndexButtonExternal;
