import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import get from "lodash/get";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import FormDateField from "../../../components/forms/FormDateField";

const GuarantorsSchema = yup.object().shape({
    director_1_first_name: yup.string().required("First name of director one is required"),
    director_1_last_name: yup.string().required("Last name of director one is required"),
    director_1_dob: yup.string().required('Date of birth is required'),
    director_1_email: yup.string().email("Must be a valid email address").required('Email address is required'),
    director_1_phone: yup.string().required('Phone is required'),
    // director_1_postal_address: yup.string().required('Postal address is required'),


    additional_director: yup.boolean(),

    director_2_first_name: yup.string()
        .when("additional_director", (additional_director, schema) => additional_director ? schema.required("First name of director two is required") : schema),
    director_2_last_name: yup.string()
        .when("additional_director", (additional_director, schema) => additional_director ? schema.required("Last name of director two is required") : schema),
    director_2_dob: yup.string()
        .when("additional_director", (additional_director, schema) => additional_director ? schema.required('Date of birth of director 2 is required') : schema),
    director_2_email: yup.string()
        .when("additional_director", (additional_director, schema) => additional_director ? schema.email("Must be a valid email address").required('Email address of director 2 is required') : schema),
    director_2_phone: yup.string()
        .when("additional_director", (additional_director, schema) => additional_director ? schema.required('Phone of director 2 is required') : schema),
    // director_2_postal_address: yup.string()
    //     .when("additional_director", (additional_director, schema) => additional_director ? schema.required('Postal address of director 2 is required') : schema)
});

const Guarantors = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: GuarantorsSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [showDirectorTwo, setShowDirectorTwo] = useState(false);

    const onSubmit = async data => {
        const manipulate = (data) => {
            const updated = {...data};
            updated.director_1_name = `${updated.director_1_first_name} ${updated.director_1_last_name}`;

            if(!updated.director_1_postal_address) {
                const combinedDir1 = [updated.director_1_postal_street, updated.director_1_postal_suburb, updated.director_1_postal_city, updated.director_1_postal_postcode].filter(Boolean).join(',');
                updated.director_1_postal_address = combinedDir1;
            }
            if(!updated.director_2_postal_address) {
                const combinedDir2 = [updated.director_2_postal_street, updated.director_2_postal_suburb, updated.director_2_postal_city, updated.director_2_postal_postcode].filter(Boolean).join(', ');
                updated.director_2_postal_address = combinedDir2;
            }

            if (updated.director_2_first_name) {
                updated.director_2_name = `${updated.director_2_first_name} ${updated.director_2_last_name}`;
            } else {
                updated.director_2_name = '';
            }
            return updated;
        };
        submitFormStep({data, step: 2, url: "/business/fuelcard", setError, manipulate});
    };

    useEffect(() => {
        if (!showDirectorTwo) {
            setValue("director_2_first_name", undefined);
            setValue("director_2_last_name", undefined);
            setValue("director_2_dob", undefined);
            setValue("director_2_email", undefined);
            setValue("director_2_phone", undefined);
            setValue("director_2_postal_address", undefined);
        }
    }, [showDirectorTwo, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(2, "/business");
        const values = loadSavedFormValues(setValue);

        if (get(values, "additional_director", false)) setShowDirectorTwo(true);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>


            <div className="section">
                <h2>Director/Trustee 1</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="First Name"
                            name="director_1_first_name"
                            ref={register}
                            id="director_1_first_name"
                            type="text"
                            required={true}
                            error={errors.director_1_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Last Name"
                            name="director_1_last_name"
                            ref={register}
                            id="director_1_last_name"
                            type="text"
                            required={true}
                            error={errors.director_1_last_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormDateField label="Date of Birth"
                                       name="director_1_dob"
                                       ref={register}
                                       id="director_1_dob"
                                       required={true}
                                       watch={watch}
                                       setValue={setValue}
                                       error={errors.director_1_dob}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="director_1_email" ref={register} id="director_1_email" type="email" required={true}
                                   error={errors.director_1_email}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="director_1_phone" ref={register} id="director_1_phone" type="text" required={true} error={errors.director_1_phone}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="director_1_postal_address"
                            register={register}
                            id="director_1_postal_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors}
                            applicantOrDirector={true}
                            extraFieldsName={"director_1_postal"}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="additional_director">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="additional_director"
                                ref={register}
                                label="We require only one director but if you wish to add another"
                                onClick={(e) => {
                                    const checked = e.target.checked;
                                    setShowDirectorTwo(checked);
                                }}  />
                        </Form.Group>
                    </div>
                </div>
            </div>


            <div className={`section ${!showDirectorTwo ? "d-none" : ""}`}>
                <h2>Director/Trustee 2</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="First Name"
                            name="director_2_first_name"
                            ref={register}
                            id="director_2_first_name"
                            type="text"
                            required={true}
                            error={errors.director_2_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Last Name"
                            name="director_2_last_name"
                            ref={register}
                            id="director_2_last_name"
                            type="text"
                            required={true}
                            error={errors.director_2_last_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormDateField label="Date of Birth"
                                       name="director_2_dob"
                                       ref={register}
                                       id="director_2_dob"
                                       required={true}
                                       watch={watch}
                                       setValue={setValue}
                                       error={errors.director_2_dob}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="director_2_email" ref={register} id="director_2_email" type="email"
                                   error={errors.director_2_email}
                                   required={true} />
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="director_2_phone" ref={register} id="director_2_phone" type="text" error={errors.director_2_phone} required={true} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="director_2_postal_address"
                            register={register}
                            id="director_2_postal_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors}
                            applicantOrDirector={true}
                            extraFieldsName={"director_2_postal"}
                        />
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/business/account" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Guarantors;
