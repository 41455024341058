import React from "react";

const DirectDebitTerms = () => (
    <>
        <p>1. The Initiator:</p>
        <p>a) has agreed to give advance notice of the net amount of each direct debit and the due date of debiting at least 10 calendar days before (but not
            more than 2 calendar months) the date the direct debit will be initiated. This notice will be provided either:</p>
        <p>i) in writing; or</p>
        <p>ii) by electronic mail where the Customer has provided prior written consent to the initiator. The advance notice will include the following
            message: “Unless advice to the contrary is received from you 3 (three) working days prior to Direct Debit date, the sum of $4444....
            will be directly debited from your bank account on the 20th or the following working day of each month.”</p>
        <p>b) may, upon the relationship which gave rise to this authority being terminated, give notice to the bank that no further Direct Debits are to be
            initiated under this authority. Upon receipt of such notice, the bank may terminate this authority as to future payments by notice in writing to me
            / us.</p>
        <p>2. The Customer:</p>
        <p>a) may, at any time, terminate this authority as to future payments by giving written notice to the bank and to the initiator; and</p>
        <p>b) may stop payment of any direct debit to be initiated under this authority by the initiator by giving written notice to the bank prior to the direct debit
            being paid by the bank.</p>
        <p>3. The Customer acknowledges that:</p>
        <p>a) This authority will remain in full force and effect in respect of all direct debits made from my / our account in good faith, notwithstanding my / our
            death, bankruptcy or other revocation of this authority until actual notice of such event is received by the bank.</p>
        <p>b) In any event, this authority is subject to any arrangement now or hereafter existing between me / us and the bank in relation to my / our account.</p>
        <p>c) Any dispute as to the correctness or validity of an amount debited to my / our account shall not be the concern of the bank, except insofar as the
            direct debit has not been paid in accordance with this authority. Any other disputes lie between me / us and the initiator.</p>
        <p>d) Where the bank has used reasonable care and skill in acting in accordance with this authority, the bank accepts no responsibility or liability in
            respect of:</p>
        <p>i) the accuracy of information about direct debits on bank statements; and</p>
        <p>ii) any variations between notices given by the initiator and the amounts of direct debits.</p>
        <p>e) The bank is not responsible for, or under any liability in respect of, the initiator’s failure to give written advance notice correctly nor for the nonreceipt or late receipt of notice by me / us for any reason whatsoever. In any such situation, the dispute lies between me / us and the initiator.</p>
        <p>4. The Bank may:</p>
        <p>a) in its absolute discretion, conclusively determine the order of priority of payment by it of any monies pursuant to this or any other authority,
            cheque or draft properly executed by me / us and given to or drawn on the bank; and</p>
        <p>b) at any time, terminate this authority as to future payments by notice in writing to me / us; and</p>
        <p>c) charge its current fees for this service in force from time to time. </p>
    </>
);

export default DirectDebitTerms;
