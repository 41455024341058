import React from "react";
import {BrowserRouter} from "react-router-dom";
import MainLayout from "../../components/layout/MainLayout";

import "./styles.scss";

const App = () => (
    <BrowserRouter>
        <MainLayout/>
    </BrowserRouter>
);

export default App;
