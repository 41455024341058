import * as yup from "yup";
import defaultTo from "lodash/defaultTo";
import isEmpty from "lodash/isEmpty";

export const hasFuelcardBpmeApp = (values) => {
    let result = false;
    values = defaultTo(values, {});
    if (!isEmpty(values)) {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (key.endsWith('fuelcard_bpme_app')) {
                    if (values[key]) result = true;
                    break;
                }
            }
        }
    }
    return result;
};

export const FuelcardSchemaBusiness = (maxFuelCards = 5) => {

    const schemaShape = {};

    for (let i = 1; i <= maxFuelCards; i++) {
        schemaShape[`fuelcard_${i}_fuelcard_type`] = yup.string()
            .when(
                "fuelcard_quantity",
                (value, schema) => Number(value) >= i ? schema.required("You must select a card type") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_name`] = yup.string()
            .when(
                `fuelcard_${i}_fuelcard_type`,
                (value, schema) => value === "Personal" ? schema.required("You must enter a card name") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_vehicle_rego`] = yup.string()
            .when(
                `fuelcard_${i}_fuelcard_type`,
                (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle registration number") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_vehicle_description`] = yup.string()
            .when(
                `fuelcard_${i}_fuelcard_type`,
                (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle description") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_pin`] = yup.string()
            .when(
                "fuelcard_quantity",
                (value, schema) => Number(value) >= i ? schema.matches(/^[0-9]{4}$/, "You must enter a 4 digit PIN for the fuelcard") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_pin_confirm`] = yup.string()
            .when(
                "fuelcard_quantity",
                (value, schema) => Number(value) >= i ?
                    schema
                        .required('You must confirm the PIN')
                        .test('pins-match', 'Confirmed PIN does not match', function(value) {
                            return this.parent[`fuelcard_${i}_fuelcard_pin`] === value;
                        })
                    :
                    schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_purchase_details`] = yup.string()
            .when(
                "fuelcard_quantity",
                (value, schema) => Number(value) >= i ? schema.required("You must enter purchase details") : schema
            );

        schemaShape[`fuelcard_${i}_fuelcard_transaction_limit`] = yup.string()
            .when(
                "fuelcard_quantity",
                (value, schema) => Number(value) >= i ? schema.required("You must enter a transaction limit") : schema
            );
    }

    schemaShape['fuelcard_quantity'] = yup.string().required("You must select a fuelcard quantity");

    return  yup.object().shape(schemaShape);

};


export const FuelcardSchemaIndividual = (prefix, extraFields = {}) => {

    const schemaShape = {...extraFields};

    // Fuelcard 1
    schemaShape[`${prefix}_1_fuelcard_type`] = yup.string().required("You must select a card type");
    schemaShape[`${prefix}_1_fuelcard_name`] = yup.string().when(
        `${prefix}_1_fuelcard_type`,
        (value, schema) => value === "Personal" ? schema.required("You must enter a card name") : schema
    );
    schemaShape[`${prefix}_1_fuelcard_vehicle_rego`] = yup.string()
        .when(
            `${prefix}_1_fuelcard_type`,
            (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle registration number") : schema
        );
    schemaShape[`${prefix}_1_fuelcard_vehicle_description`] = yup.string()
        .when(
            `${prefix}_1_fuelcard_type`,
            (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle description") : schema
        );
    schemaShape[`${prefix}_1_fuelcard_pin`] = yup.string().matches(/^[0-9]{4}$/, "You must enter a 4 digit PIN for the fuelcard");
    schemaShape[`${prefix}_1_fuelcard_pin_confirm`] = yup
        .string()
        .required('You must confirm the PIN')
        .test('pins-match', 'Confirmed PIN does not match', function(value) {
            return this.parent[`${prefix}_1_fuelcard_pin`] === value;
        });
    schemaShape[`${prefix}_1_fuelcard_purchase_details`] = yup.string().required("You must enter purchase details");
    schemaShape[`${prefix}_1_fuelcard_transaction_limit`] = yup.string().required("You must enter a transaction limit");


    // Fuelcard 2
    schemaShape[`${prefix}_2_fuelcard_type`] = yup.string()
        .when(
            `additional_${prefix}`,
            (value, schema) => value === "true" ? schema.required("You must select a card type") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_name`] = yup.string()
        .when(
            "_2_fuelcard_type",
            (value, schema) => value === "Personal" ? schema.required("You must enter a card name") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_vehicle_rego`] = yup.string()
        .when(
            "_2_fuelcard_type",
            (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle registration number") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_vehicle_description`] = yup.string()
        .when(
            "_2_fuelcard_type",
            (value, schema) => value === "Vehicle" ? schema.required("You must enter a vehicle description") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_pin`] = yup.string()
        .when(
            `additional_${prefix}`,
            (value, schema) => value === "true" ? schema.matches(/^[0-9]{4}$/, "You must enter a 4 digit PIN for the fuelcard") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_pin_confirm`] = yup.string()
        .when(
            `additional_${prefix}`,
            (value, schema) => value === "true" ?
                schema.required('You must confirm the PIN')
                    .test('pins-match', 'Confirmed PIN does not match', function(value) {
                        return this.parent[`${prefix}_2_fuelcard_pin`] === value;
                    })
                : schema
        );
    schemaShape[`${prefix}_2_fuelcard_purchase_details`] = yup.string()
        .when(
            `additional_${prefix}`,
            (value, schema) => value === "true" ? schema.required("You must enter purchase details") : schema
        );
    schemaShape[`${prefix}_2_fuelcard_transaction_limit`] = yup.string()
        .when(
            `additional_${prefix}`,
            (value, schema) => value === "true" ? schema.required("You must enter a transaction limit") : schema
        );

    return yup.object().shape(schemaShape);

};

export const FuelCardSchemaAviation = (avgasMaxAircraft = 6, jeta1MaxAircraft = 6) => {
    const schemaShape = {};

    for (let i = 1; i <= avgasMaxAircraft; i++) {
        schemaShape[`avgas_aircraft_${i}_registration`] = yup.string().required("You must enter the aircraft's registration");
        schemaShape[`avgas_aircraft_${i}_type`] = yup.string().required("You must enter the aircraft type");
        schemaShape[`avgas_aircraft_${i}_fuel_grade`] = yup.string().required("You must confirm the fuel grade");
    }

    for (let i = 1; i <= jeta1MaxAircraft; i++) {
        schemaShape[`jeta1_aircraft_${i}_registration`] = yup.string().required("You must enter the aircraft's registration");
        schemaShape[`jeta1_aircraft_${i}_type`] = yup.string().required("You must enter the aircraft type");
        schemaShape[`jeta1_aircraft_${i}_fuel_grade`] = yup.string().required("You must confirm the fuel grade");
    }

    return yup.object().shape(schemaShape);
};
