import React from "react";

const HomeHeating = () => (
    <>
        <p>1. Definitions and Interpretation</p>
        <p>1.1 In these Terms, the following terms have the following meanings:</p>
        <p>(a) "Authorised User" means any person authorised by the Fuelcard Account Holder or Cardholder to use a Fuelcard.</p>
        <p>(b) "BP" means BP Oil New Zealand Limited.</p>
        <p>(c) “BusinessDay” means a day (other than a Saturday, Sunday or statutory public holiday) on which registered banks are open for general business in Christchurch, New Zealand.</p>
        <p>(d) "Cardholder" means the person named on a Fuelcard.</p>
        <p>(e) Customer" means the Fuelcard Account Holder, and where the context permits, includes each Cardholder and Authorised User of any Fuelcard issued in respect of the Account Holder's Fuelcard Account, and/or the Applicant(s) named on the front page of these Terms and/or any other person who is supplied with Goods or who requests or has agreed to the supply of Goods from RD Petroleum (as the case may be).</p>
        <p>(f) "Fuelcard" means a RD Petroleum Fuelcard issued by BP to a Fuelcard Account Holder for use by persons or vehicles nominated by the Fuelcard Account Holder and includes any Mobile Fuelcard App registered on any Customer’s device.</p>
        <p>(g) "Fuelcard Account" means all accounts (however titled) provided by RD Petroleum to record transactions made by the Account Holder or on behalf of the Account Holder by any Cardholder or Authorised User.</p>
        <p>(h) “Goods” means any products, goods and/or services acquired using the Fuelcard or Fuelcard Account.</p>
        <p>(i) "Guarantor" means any guarantor of the Customer from time to time having signed any guarantee in favour of RD Petroleum. Where there is more than one guarantor of the Customer, the term "Guarantor" shall mean and refer to each of the Guarantors individually and both of them together unless the context otherwise requires, and the obligations and agreements on the part of the Guarantors shall bind each of them jointly and severally.</p>
        <p>(j) “Mobile Fuelcard App” means BP Oil NZ Ltd’s App, (including any updated versions) enabling purchases on the Fuelcard Account from any customers device.</p>
        <p>(k) "PIN" or “Password” means the confidential personal identification number or password for use with a Fuelcard.</p>
        <p>(l) "PPSA" means the Personal Property Securities Act 1999.</p>
        <p>(m) “RD Petroleum” means RD Petroleum Limited.</p>
        <p>(n) “Terms” means these Terms of Trade, as amended by RD Petroleum from time to time.</p>
        <p>1.2 The rule of construction known as the contra proferentem rule does not apply to these Terms.</p>
        <p>1.3 Words importing the singular include the plural and vice versa.</p>
        <p>1.4 Headings are for convenience only and do not form part of, or affect the interpretation of, these Terms.</p>
        <p>1.5 References to a party include that party's successors, personal representatives, executors, administrators and permitted assigns.</p>
        <p>1.6 If the Customer comprises more than one person, each of those person’s liability shall be joint and several.</p>
        <p>1.7 References to a statute include references to:</p>
        <p>(a) regulations, orders, rules or notices made pursuant to that statute;</p>
        <p>(b) all amendments to that statute and those regulations, orders, rules or notices, whether by subsequent statute or otherwise; and</p>
        <p>(c) any statute passed in substitution of that statute.</p>
        <p>2. The RD Petroleum Fuelcard</p>
        <p>2.1 The Fuelcard(s) is issued by BP for person(s) or vehicle(s) nominated by the Account Holder. The Account Holder is responsible for all use of Fuelcard(s) by each Cardholder and Authorised User, and for ensuring each Cardholder and Authorised User understands and complies with these Terms. Where applicable, references to “you” will be taken as including each Cardholder and Authorised User.</p>
        <p>2.2 When a physical Fuelcard is received it must immediately be endorsed with the signature of Cardholder, or the registration number of the vehicle identified on the card, in the space provided. A Fuelcard must not be used before it has been endorsed. By endorsing the card or using it or by downloading and registering the Mobile Fuel Card App you agree to be bound by these Terms.</p>
        <p>2.3 Each Fuelcard is supplied with a specific PIN or Password, linked to the Fuelcard Account. This must be kept secure and confidential at all times and should not be disclosed to any unauthorised persons. Do not write the PIN or Password on the Fuelcard and do not disclose it to anyone other than the Cardholder and Authorised Users. It is the Account Holder's responsibility to safeguard the Fuelcard(s) and the PIN or Password. You must notify RD Petroleum immediately if the Fuelcard is lost or stolen or if the PIN or Password has been disclosed to an unauthorised person. You will be liable for any unauthorised use of the Fuelcard until such time as RD Petroleum has received and given written acknowledgement of such notification and cancelled or deregistered the Fuelcard in accordance with these Terms.</p>
        <p>2.4 You acknowledge that all Fuelcards are owned by and remain the sole property of RD Petroleum at all times. RD Petroleum may, at any time, require the return of a physical Fuelcard or suspend or terminate the Mobile Fuelcard App for any reason.</p>
        <p>Use</p>
        <p>2.5 A Fuelcard may be used to purchase BP specified products (including services) at participating retailers and automatic facilities provided by BP. It cannot be used to obtain cash, for the refund of a product or otherwise. It is your responsibility to ensure that you do not exceed any purchase limitation. Any purchase is strictly personal and not for resale.</p>
        <p>2.6 By signing the sales docket provided by the retailer or entering the Fuelcard PIN or using the Mobile Fuelcard App, you will be deemed to have authorised the product purchase and any costs to be charged by RD Petroleum to your Fuelcard Account. It is your responsibility as Customer to ensure that the sales docket correctly records the quantity of product and other details.</p>
        <p>3. Trading Issues</p>
        <p>3.1 Any price list or marketing advice supplied to the Customer does not constitute an offer for sale, and RD Petroleum reserves the right to decline any order for Goods without giving any reason. All prices are subject to change without notice and at the sole discretion of RD Petroleum. Where the price of Goods changes after such Goods are ordered by the Customer but before the Goods are delivered, the price charged for the Goods shall be the price applicable at the time of delivery. RD Petroleum reserves the right to terminate any agreement to supply Goods for any reason whatsoever by giving notice of such termination to the Customer. RD Petroleum shall have no liability to the Customer as a result of or in relation to such termination.</p>
        <p>4. Delivery and Risk</p>
        <p>4.1 RD Petroleum may charge the Customer for any storage and transportation expenses incurred if the Customer fails or refuses to take or accept delivery or indicates to RD Petroleum that it will fail to do so.</p>
        <p>4.2 If RD Petroleum agrees (at its discretion) to deliver Goods to the Customer, such Goods shall be delivered (at the Customer's risk) to the address specified by the Customer, unless otherwise notified by RD Petroleum and agreed to by the Customer prior to delivery.</p>
        <p>4.3 If RD Petroleum has given the Customer a time for collection or delivery of the Goods (or any part of them), such time is approximate only and is not deemed to be of the essence.</p>
        <p>4.4 RD Petroleum reserves the right to deliver Goods by instalments.</p>
        <p>4.5 RD Petroleum shall not be liable in any way for any loss or damage (including consequential loss or damage) suffered by the Customer or any other person as a result of RD Petroleum's failure to deliver Goods at the time and place specified, unless such failure is due to RD Petroleum's gross negligence or wilful misconduct. RD Petroleum shall have no liability whatsoever in respect of loss or damage arising from any delays in delivery beyond RD Petroleum’s reasonable control.</p>
        <p>4.6 Risk (but not title) in Goods shall pass to the Customer at the earlier of:</p>
        <p>(a) when the Goods are collected by or delivered to the Customer or any agent of the Customer;</p>
        <p>(b) when the Goods are in the control of the Customer; or</p>
        <p>(c) when the Customer has title to the Goods pursuant to these Terms.</p>
        <p>4.7 If so requested by RD Petroleum, the Customer shall insure Goods for their full price, and shall not sell, dispose or part with possession of such Goods or do anything else inconsistent with RD Petroleum's ownership of the Goods from the date that risk in the Goods has passed until such time as title in the Goods passes to the Customer under these Terms.</p>
        <p>5. Ownership and Security Interest</p>
        <p>5.1 Ownership and title in Goods shall not pass upon delivery, but shall remain with RD Petroleum until full payment for all monies owing by the Customer to RD Petroleum has been made. RD Petroleum shall retain title to all Goods supplied until such Goods have been paid for in full and the Customer has performed all of its other obligations under these Terms.</p>
        <p>5.2 Until all monies owing by the Customer to RD Petroleum have been paid:</p>
        <p>(a) the Customer holds the Goods supplied as fiduciary for RD Petroleum and will deal with them as agent for and on behalf of RD Petroleum (but will not hold themselves out as agent of RD Petroleum to any third parties);</p>
        <p>(b) the Customer shall store the Goods separately consistent with the Goods being RD Petroleum’s property, and shall ensure that such Goods are at all times able to be separately identified;</p>
        <p>(c) if the Customer resells the Goods supplied, the proceeds of any resale will belong to RD Petroleum, and the Customer will pay the same into a separate account for which separate records are kept, and all claims which the Customer holds against third parties will be handed over to RD Petroleum;</p>
        <p>(d) the Customer irrevocably gives RD Petroleum and its agents the right to enter the Customer’s premises or upon any third party’s premises where Goods are located, to remove any of the Goods supplied and resell them, and RD Petroleum shall not be responsible nor liable in any manner whatsoever from any damage caused or any loss that results from such action;</p>
        <p>(e) if any of the Goods become part of a product or mass (through, or by whatever process) such that the identity of those Goods is lost in the product or mass, the security interest created by this clause continues in the product or mass in accordance with the Personal Property Securities Act 1999 (PPSA); and</p>
        <p>(f) if any of the Goods are installed in, or affixed to, and become an accession to, other goods, the security interest continues in the accession in accordance with the PPSA.</p>
        <p>6. Personal Property Securities Act 1999 (PPSA)</p>
        <p>Security:</p>
        <p>6.1 The Customer acknowledges and agrees that:</p>
        <p>(o) by assenting to these Terms, the Customer grants a security interest (by virtue of the retention of title at clause 5.1 of these Terms) to RD Petroleum in all Goods previously supplied by RD Petroleum to the Customer (if any) and all after acquired Goods supplied by RD Petroleum to the Customer (or for the Customer’s account);</p>
        <p>(p) by assenting to these Terms, the Customer grants RD Petroleum security in all its present and after acquired property, on the terms set out in Auckland District Law Society Inc's General Security Agreement (REF: 6301); and</p>
        <p>(q) these Terms shall apply notwithstanding anything, express or implied, to the contrary contained in the Customer’s purchase order.</p>
        <p>6.2 The Customer agrees to give any further securities as RD Petroleum may from time to time require, in the form required by RD Petroleum, and to comply with all of the Customer's obligations under those securities.</p>
        <p>6.3 The Customer shall not grant any other person with any security interest or lien over Goods that RD Petroleum has a security interest in.</p>
        <p>Financing Statement:</p>
        <p>6.4 The Customer undertakes to:</p>
        <p>(a) promptly sign any further documents and/or provide any further information (which information the Customer warrants to be complete, accurate and up-to-date in all respects) which RD Petroleum may reasonably require to enable registration of a financing statement or financing change statement on the Personal Property Securities Register;</p>
        <p>(b) do anything else required by RD Petroleum to ensure its security interest constitutes a first ranking perfected security interest in the Goods;</p>
        <p>(c) not register a financing change statement or a change demand in respect of the Goods (as those terms are defined in the PPSA) without RD Petroleum’s prior written consent; and</p>
        <p>(d) give RD Petroleum not less than 14 days prior written notice of any proposed change in the Customer’s name and/or any other change in the Customer’s details (including, but not limited to, changes in the Customer’s address, facsimile number, trading name or business practice).</p>
        <p>6.5 The Customer shall pay all costs, expenses and other charges incurred by RD Petroleum in relation to:</p>
        <p>(a) the filing of a financing statement or financing change statement; and</p>
        <p>(b) any disputes or negotiations with third parties claiming an interest in the Goods.</p>
        <p>7. Payment</p>
        <p>7.1 All prices quoted by RD Petroleum are exclusive of GST and other taxes and duties, which shall be paid by the Customer. Such prices are subject to change at any time without notice pursuant to clause 3.1 of these Terms.</p>
        <p>7.2 The Customer shall pay RD Petroleum all amounts owing for Goods within 7 days from date of invoice. To avoid any doubt, RD Petroleum's receipt of any cheque, bill of exchange, or other negotiable instrument will not constitute payment by the Customer and the Customer will remain liable for payment until such time as payment is actually received by RD Petroleum.</p>
        <p>7.3 RD Petroleum may notify the Customer at any time that RD Petroleum is going to stop supplying Goods to the Customer on credit. This shall be without prejudice to the Customer's obligation to pay any amounts owing to RD Petroleum.</p>
        <p>7.4 Where the Customer has more than one account with RD Petroleum, RD Petroleum may apportion payments to outstanding accounts as it thinks fit.</p>
        <p>8. Payment - Fuelcard</p>
        <p>8.1 RD Petroleum will debit your nominated bank account with the amount payable under the Fuelcard Account, not earlier than the 20th day of each month. The amount payable will include all purchases made with a Fuelcard on your Fuelcard Account. It may also include GST and any other taxes, duties and levies payable in respect of the Goods. Please ensure that your nominated bank account has sufficient funds.</p>
        <p>8.2 If any debit from your Bank Account is dishonoured, RD Petroleum reserves the right to suspend the use of your card until all monies owing are received in full. All payments made to RD Petroleum are to be made in full without any withholding, deduction or set off. Where the Customer has more than one account with RD Petroleum, RD Petroleum may apportion payments to outstanding accounts as it thinks fit.</p>
        <p>8.3 RD Petroleum may at its sole discretion charge default interest on overdue accounts at the rate of 2.5% per month calculated daily from the due date until payment has been received in full. RD Petroleum may also charge the Account Holder for any legal fees or collection costs incurred to recover the full amount owing in respect of the Fuelcard Account.</p>
        <p>8.4 Unless you notify RD Petroleum within 30 days, any amount debited by RD Petroleum is accepted as correct. You cannot dispute any debit because of a fault in a product purchased using the Fuelcard. Such a complaint must be raised with the participating retailer or under the procedure below.</p>
        <p>8.5 RD Petroleum will provide you with a monthly report on the Fuelcard(s) transactions</p>
        <p>8.6 If you return any defective products to the participating retailer with the sales docket, and it is accepted, the retailer will give you a credit voucher which will be recorded against your Fuelcard account.</p>
        <p>9. Liability</p>
        <p>9.1 RD Petroleum is not liable for the actions of any participating retailer or any product sold by the retailer. RD Petroleum is also not liable for any loss or liability that you incur directly or indirectly due to fault in the Fuelcard or any automatic facility. If you notify RD Petroleum of any defective products from an automatic facility within 48 hours of delivery, RD Petroleum will issue a credit to you which will be recorded against your Fuelcard Account, or replace the product.</p>
        <p>8.2 For the Mobile Fuel Card App RD Petroleum is not liable for any loss or liability that you incur directly or indirectly (whether to any Customer’s device or otherwise) due to the installation and use of the Mobile Fuelcard App.</p>
        <p>10. Cancellation and Termination - Fuelcard</p>
        <p>10.1 You may cancel any physical Fuelcard that you no longer require by returning it to RD Petroleum. Your Fuelcard Account will be charged with any goods purchased using the Fuelcard App up to the time that RD Petroleum receives the such card. For any registered Mobile Fuelcard App you may deregister by sending an email cancellation request to fuelcard@rdp.co.nz. Cancellation requests for Mobile Fuelcard Apps will only be processed during the hours of 8.30am to 4.00pm on Business Days. Your Fuelcard Account will be charged with any Goods purchased using that Mobile Fuelcard App up to the time that RD Petroleum processes the cancellation request. Your Fuelcard Account will remain active for any other Fuelcards.</p>
        <p>10.2 You may close your Fuelcard Account by giving RD Petroleum 14 days written notice and returning all Fuelcards issued to you and sending an email request to deregister a Mobile Fuelcard App in accordance with clause 8.1. Before returning any physical Fuelcards, please cut them in half.</p>
        <p>10.3 RD Petroleum may close your Fuelcard Account without notice if you fail to comply with these Terms, or if you fail to pay your debit to RD Petroleum. In other cases, RD Petroleum may close your Fuelcard Account by giving you fourteen (14) days' notice. When your Fuelcard Account is closed, the full amount outstanding in your Fuelcard Account and any additional charge or interest becomes payable immediately. Interest will accrue until the Fuelcard Account is settled in full.</p>
        <p>11. Loss of Fuelcard</p>
        <p>11.1 If any Fuelcard is lost, stolen or misused, you must notify RD Petroleum immediately and confirm the notification in writing within 24 hours. RD Petroleum shall not be liable for any unauthorised use or a Fuelcard until RD has given written acknowledgment of this notification. You will not be liable for any unauthorised transaction on the Fuelcard that is made after RD Petroleum receives and has given acknowledgement of such notification.</p>
        <p>12. Changes and Notices</p>
        <p>12.1 RD Petroleum may amend all or any part of these Terms without obtaining the consent of its Customers. RD Petroleum may at its discretion advise when changes to these Terms are to take effect by giving fourteen (14) days' notice. Any notices will be sent to your last known postal address.</p>
        <p>Waiver and Contracting Out:</p>
        <p>12.2 Unless otherwise agreed to in writing by RD Petroleum, the Customer waives their right to receive a copy of any verification statement in respect of RD Petroleum's security interests under the PPSA.</p>
        <p>12.3 To the maximum extent permitted by law, the Customer waives their rights and, with RD Petroleum’s agreement, contract out of their rights under the sections referred to in sections 107(2)(c) to (e) and (g) to (i) of the PPSA.</p>
        <p>12.4 The Customer agrees that nothing in sections 114(1)(a), 116, 120(2), 133 and 134 of the PPSA shall apply to these Terms and, with RD Petroleum’s agreement, contract out of such sections.</p>
        <p>12.5 The Customer and RD Petroleum agree that section 109(1) of the PPSA is contracted out of in respect of particular Goods if and only for so long as RD Petroleum is not the secured party with priority over all other secured parties in respect of those Goods.</p>
        <p>13. Agreement To Mortgage</p>
        <p>13.1 Whether or not any collateral security is given by the Customer and/or the Guarantor to RD Petroleum, the Customer and Guarantor acknowledge and agree that RD Petroleum shall be entitled at any time to require the Customer and/or Guarantor to execute a registrable mortgage or charge, or further mortgage or charge as the case may be, over any interest in land (including the Property) or other property whether real or personal as may be required by RD Petroleum to secure all amounts owed by the Customer to RD Petroleum. Any mortgage or charge required under this clause shall be prepared and registered by RD Petroleum at the cost of the Customer and/or Guarantor and shall contain such terms and conditions and have such priority as may reasonably be required by RD Petroleum.</p>
        <p>13.2 The Customer and the Guarantor irrevocably appoint RD Petroleum (including the person or persons for the time being entitled to give receipt or discharge for the moneys hereby secured) the true and lawful attorney or attorneys of the Customer and/or Guarantor for the purpose of executing and registering a mortgage or a charge under these Terms. Without limiting this clause 7, “charge” shall include any security registered under the Personal Property Securities Act 1999.</p>
        <p>14. Limitation of Liability</p>
        <p>14.1 If the Customer acquires Goods for the purpose of a business, the Customer agrees and acknowledges that it is not a “consumer” as defined by the Consumers Guarantees Act 1993 (“the Consumer Act”) and that the Consumer Act does not apply to such Goods . The Customer further agrees that the guarantees and rights available to it under the Consumer Act, including the right to consequential losses, are expressly excluded under these Terms pursuant to section 43(2) of the Consumer Act. However, RD Petroleum does not contract out of the provisions of the Consumer Act, except to the extent permitted by that Act.</p>
        <p>14.2 The warranties and any liability implied by section 16 of the Sale of Goods Act 1908 are expressly excluded by RD Petroleum pursuant to section 56 of that Act.</p>
        <p>14.3 RD Petroleum makes no representation or warranty about the quality or merchantability or fitness for purpose of any Goods and excludes all express or implied warranties and representations whatsoever in respect of the Goods except to the extent that such representations or warranties cannot be excluded by law.</p>
        <p>14.4 RD Petroleum is prepared to give consideration to any reasonable complaint as to either manufacture or workmanship. If, in its sole opinion, RD Petroleum concludes that the complaint is justified, it shall endeavour to obtain redress/compensation from the manufacturer.</p>
        <p>14.5 The Customer shall ensure that its terms of sale requires purchasers of Goods to comply with and be bound by the applicable causes of these terms, and the Customer indemnifies RD Petroleum for any losses incurred as a result of its omission to do so.</p>
        <p>14.6 Except as expressly otherwise provided by these Terms, RD Petroleum shall not be liable for any loss or damage or liability of any kind whatsoever (including consequential loss or lost profit or business) whether suffered or incurred by the Customer or another person and whether in contract, or tort, or otherwise and whether such loss or damage arises directly or indirectly from Goods provided by RD Petroleum.</p>
        <p>14.7 However, to the extent that RD Petroleum is liable for any reason (notwithstanding clause 8.6) for any loss suffered or liability incurred by the Customer arising from any breach of these Terms or for any other reason, such liability is limited in each case to the amount of the price of the Goods concerned.</p>
        <p>14.8 If Goods are returned by the Customer, or if the Customer makes a claim in writing to RD Petroleum in relation to Goods provided, RD Petroleum may, in its discretion, repair or replace the Goods, or refund the price of those Goods to the Customer, provided that:</p>
        <p>(a) the Goods must be returned or the claim must be made in writing to RD Petroleum within 14 days of delivery;</p>
        <p>(b) the Customer must supply the date and number of any invoice relating to the Goods; and</p>
        <p>(c) RD Petroleum must be given a reasonable opportunity to inspect the Goods.This clause 8.8 is subject to any obligations RD Petroleum may have to the Customer in respect of any consumer goods under the Consumer Act.</p>
        <p>15. Default</p>
        <p>15.1 The Customer will be in default of these Terms if:</p>
        <p>(a) the Customer fails to pay an amount due under these Terms by the due date for payment;</p>
        <p>(b) the Customer commits a breach of any of its other obligations under these Terms, any security documentation, or the terms of any other contract the Customer has entered into, or enters into in the future, with RD Petroleum or one of its related entities;</p>
        <p>(c) the Customer becomes insolvent, is wound up, has a receiver appointed, enters into any composition or arrangement with its creditors, does any act which would render it liable to be placed in liquidation or has a receiver appointed over its property, commits an act of bankruptcy, ceases to be of full legal capacity, or dies;</p>
        <p>(d) Goods that RD Petroleum has retained title to are at risk; or</p>
        <p>(e) an event or a series of events (whether related or not) occurs which, in RD Petroleum's opinion, may cause a material adverse change in the Customer's ability to meet your obligations to us.</p>
        <p>15.2 If the Customer is in default under clause 15.1 then RD Petroleum may, at its option, do any one or more of the following:</p>
        <p>(a) charge default interest at the rate of 2.5% per month on any late payments calculated on a daily basis from the due date until the date payment is received;</p>
        <p>(b) require the Customer to remedy the default in the manner and within a period specified by RD Petroleum;</p>
        <p>(c) require the Customer to pay all amounts owed to RD Petroleum immediately;</p>
        <p>(d) suspend or terminate the Customer's account(s) with RD Petroleum;</p>
        <p>(e) enforce any security interests created by these Terms; and/or</p>
        <p>(f) exercise any rights that it has under these Terms or that are available to RD Petroleum at law.</p>
        <p>15.3 The exercise of any of these rights on default (including the right to charge interest) is without prejudice to RD Petroleum’s other rights in respect of such default, whether arising under these Terms or otherwise available at law.</p>
        <p>15.4 RD Petroleum further reserves the right to recover from the Customer all costs and/or expenses incurred in repossessing Goods or instructing a solicitor or debt collection agency to recover any amount overdue for payment, and such costs and expenses incurred shall bear interest at the rate of 2.5% per month calculated on a daily basis from date that such costs are charged to the Customer until the date that payment is received by RD Petroleum.</p>
        <p>15.5 RL Petroleum may suspend or terminate the Customer's account with RD Petroleum at any time in its sole discretion. If the Customer's account is so terminated, the Customer must immediately pay any amount owed to RD Petroleum and such termination will not affect any of RD Petroleum's rights that have arisen before termination.</p>
        <p>16. Indemnity</p>
        <p>16.1 The Customer indemnifies RD Petroleum against any claim, loss, liability or expense (including without limitation legal fees, costs and disbursements) brought or threatened against or incurred by RD Petroleum arising directly or indirectly from a breach of these Terms, negligence or wilful misconduct by the Customer except to the extent that the claim, loss, liability or expense arises directly from an act of omission by RD Petroleum.</p>
        <p>17. Collection of Information</p>
        <p>17.1 The Customer, noting the requirements under the Privacy Act 1993, acknowledges, authorises and directs that RD Petroleum can:</p>
        <p>(a) seek and obtain information about the creditworthiness or business standing of the Customer from any other person (including any other trader, merchant, firm, organisation, company or other agency or source whatsoever, including any credit agency or association);</p>
        <p>(b) use and retain such information about the Customer's creditworthiness or business standing for any legitimate purpose;</p>
        <p>(c) supply and disclose such information to any other person; and (d) supply and disclose any other information about the Customer to:</p>
        <p>(i) any person who guarantees, or who provides insurance, or who provides any other credit support, in relation to the Customer's obligations to RD Petroleum; and/or</p>
        <p>(ii) such persons as may be necessary or desirable to enable RD Petroleum to exercise any power or enforce or attempt to enforce any of its rights, remedies and powers under these Terms.</p>
        <p>17.2 The Customer authorises and directs such other persons referred to in clause</p>
        <p>11.1(a) to supply, receive and record such information to and from RD Petroleum.</p>
        <p>18. Guarantee and Indemnity</p>
        <p>18.1 In consideration of RD Petroleum agreeing to supply Goods from time to time to the Customer at the request of the Guarantor(s), the Guarantor(s) unconditionally and irrevocably:</p>
        <p>(a) guarantee the due and punctual payment to RD Petroleum of all moneys which are from time to time owing to RD Petroleum by the Customer (together with all interest) in respect of any goods and/or services supplied or to be supplied by RD Petroleum to the Customer or any other liability of the Customer to RD Petroleum, and the due observance and performance by the Customer of all its obligations contained or implied in any contract with RD Petroleum. If for any reason the Customer does not pay any amount owing to RD Petroleum, the Guarantor will immediately on demand pay the relevant amount to RD Petroleum; and (b) hold harmless and indemnify RD Petroleum on demand as a separate obligation against any liability (including but not limited to damages, costs, losses and legal fees) incurred by or assessed against RD Petroleum in connection with:</p>
        <p>(i) the supply of goods and/or services to the Customer;</p>
        <p>(ii) the recovery of moneys owing to RD Petroleum by the Customer including the enforcement of this Guarantee and Indemnity, and including but not limited to collection agency costs or collection and legal costs calculated on a solicitor and own client bases; or</p>
        <p>(iii) moneys paid by RD Petroleum with the Customer’s consent in settlement of a dispute that arises or results from a dispute between RD Petroleum, the Customer, and a third party or any combination thereof, over the supply of goods and/or services by RD Petroleum to the Customer.</p>
        <p>18.2 The Guarantor(s) further acknowledge and agree that:</p>
        <p>(a) this Guarantee and Indemnity shall constitute an unconditional and continuing guarantee and indemnity and accordingly shall be irrevocable and remaining in full force and effect until the whole of moneys owing to RD Petroleum by the Customer and all obligations under these Terms have been fully paid, satisfied and performed;</p>
        <p>(b) no granting of credit, extension of further credit, or granting of time and no waiver, indulgence or neglect to sue on RD Petroleum’s part (whether in respect of the Customer or any one or more of any other Guarantor(s) or otherwise) and no failure by any named Guarantor to properly execute this Guarantee and Indemnity or any variation, amendment or addition to any agreement in respect of the supply of goods or services shall impair or limit the liability of any Guarantor under this Guarantee and Indemnity. Without affecting the Customer’s obligations to RD Petroleum, each Guarantor shall be deemed a principal debtor and be liable to RD Petroleum accordingly;</p>
        <p>(c) no Guarantor is released from their obligations under this Guarantee and Indemnity by reason of any act, matter or thing which would release a surety only; and</p>
        <p>(d) if any payment received or recovered by RD Petroleum is avoided by law, such payment shall be deemed not to have discharged the liability of the Guarantor and the Guarantor and RD Petroleum shall each be restored to the position in which they would have been had no such payment been made.</p>
        <p>19. General Terms</p>
        <p>19.1 In the case of any conflict between these Terms and any other document provided by RD Petroleum, these Terms shall prevail. These Terms can be amended by RD Petroleum at any time by giving notice in writing of such amendment to the Customer. These Terms shall not be amended, modified or varied by the Customer unless expressed in writing and signed by RD Petroleum.</p>
        <p>19.2 Where RD Petroleum fails to enforce any terms or fails in any way to exercise its rights under these Terms, RD Petroleum shall not be deemed to have waived those rights with respect to any breach or subsequent breach of any term(s). A waiver by RD Petroleum will not be effective unless it is in writing. A waiver of any particular breach will not be a waiver of any other breach.</p>
        <p>19.3 If any part of these Terms is held to be invalid or unenforceable for whatever reason, the remaining parts shall not be affected and shall remain in full force and effect.</p>
        <p>19.4 The Customer, by making an order for Goods pursuant to the attached price list or by any other medium, does so on the basis that the Customer has read, understood and agrees to be bound by these Terms. Should the Customer have any queries regarding these Terms, it should seek independent legal advice.</p>
        <p>19.5 The Customer will not directly or indirectly transfer or assign any of its rights, interests or obligations under these Terms without RD Petroleum's prior written consent. To avoid any doubt, any change in the Customer's effective ownership or control will be deemed to be an assignment for the purpose of this clause.</p>
        <p>19.6 These Terms are governed by New Zealand laws and the parties submit to the non-exclusive jurisdiction of the New Zealand courts.</p>
        <p>19.7 RD Petroleum shall not be liable for any delay or failure to perform its obligations under these Terms if the cause of delay or failure is beyond its reasonable control.</p>
    </>
);

export default HomeHeating;
