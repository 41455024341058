import React, {useCallback, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import get from "lodash/get";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import InputMask from "react-input-mask";
import FormField from "../../../components/forms/FormField";


const FuelcardSchema = yup.object().shape({
    fuelcard_card_number: yup.string().matches(/^[0-9]{16}$/, "You must enter your 16 digit fuelcard number"),

    fuelcard_replacement_reason: yup.string()
        .when(
            "fuelcard_require_replacement",
            (value, schema) => value === true ? schema.required("You must enter a replacement reason") : schema
        ),

    fuelcard_change_type: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.required("You must select a card type");
        }
        return schema;
    }),

    fuelcard_change_name: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.when('fuelcard_change_type', (value, schema) => {
                if (value === "Personal") return schema.required("You must enter a card name");
                return schema;
            });
        }
        return schema;
    }),

    fuelcard_change_vehicle_rego: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.when('fuelcard_change_type', (value, schema) => {
                if (value === "Vehicle") return schema.required("You must enter a vehicle registration number");
                return schema;
            });
        }
        return schema;
    }),

    fuelcard_change_vehicle_description: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.when('fuelcard_change_type', (value, schema) => {
                if (value === "Vehicle") return schema.required("You must enter a vehicle description");
                return schema;
            });
        }
        return schema;
    }),

    fuelcard_change_pin: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.matches(/^[0-9]{4}$/, "You must enter a 4 digit PIN for the fuelcard");
        }
        return schema;
    }),

    fuelcard_change_pin_confirm: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.required('You must confirm the PIN')
                .test('pins-match', 'Confirmed PIN does not match', function (value) {
                    return this.parent[`fuelcard_change_pin`] === value;
                });
        }
        return schema;
    }),

    fuelcard_change_purchase_details: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.required("You must enter purchase details");
        }
        return schema;
    }),

    fuelcard_change_transaction_limit: yup.string().when('fuelcard_update_details', (value, schema) => {
        if (value) {
            return schema.required("You must enter a transaction limit");
        }
        return schema;
    })
});

const Fuelcard = () => {
    const {register, handleSubmit, errors, setError, setValue, getValues} = useForm({validationSchema: FuelcardSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [requireReplacementCard, setRequireReplacementCard] = useState(false);
    const [requireUpdateCardDetails, setRequireUpdateCardDetails] = useState(false);
    const [fuelCardType, setFuelCardType] = useState("Personal");

    const purchaseDetails = [
        "Fuel only",
        "Fuel and oil only",
        "Fuel, oil and wash",
        "Diesel only",
        "Diesel and oil only"
    ];

    const transactionLimits = [
        "$100",
        "$150",
        "$200",
        "$250",
        "$400",
        "$500",
        "$1000",
        "$2000",
        "$5000"
    ];

    const onSubmit = async data => {
        submitFormStep({data, step: 2, url: "/update-fuelcard/complete", setError});
    };

    const onCardNumberChange = useCallback((e) => {
        setValue("fuelcard_card_number", e.target.value.replace(/[^\d]+/g, ''));
    }, [setValue]);

    useEffect(() => {
        if (!requireReplacementCard) {
            setValue("fuelcard_replacement_reason", undefined);
        }
        if (!requireUpdateCardDetails) {
            setValue("fuelcard_update_details", undefined);
        }
    }, [requireReplacementCard, requireUpdateCardDetails, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(2, "/update-fuelcard");
        const values = loadSavedFormValues(setValue);

        if (get(values, "fuelcard_require_replacement", false)) setRequireReplacementCard(true);
        if (get(values, "fuelcard_update_details", false)) setRequireUpdateCardDetails(true);

        if (values["change_fuelcard_type"]) setFuelCardType(values["change_fuelcard_type"]);

    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>
            <div className="section">
                <h2>Card Details</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId="fuelcard_card_number">
                            <Form.Label>Card Number <span className="form-required">*</span></Form.Label>
                            <InputMask
                                mask="9999-9999-9999-9999"
                                maskChar=" "
                                onChange={onCardNumberChange}
                                className={`form-control ${errors.fuelcard_card_number ? 'is-invalid' : ''}`}/>
                            <input
                                type="hidden"
                                name="fuelcard_card_number"
                                ref={register}/>
                            {
                                !!errors.fuelcard_card_number &&
                                <Form.Control.Feedback type="invalid">
                                    {errors.fuelcard_card_number.message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="fuelcard_require_replacement">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="fuelcard_require_replacement"
                                ref={register}
                                label="Do you require a replacement card?"
                                onClick={(e) => {
                                    const checked = e.target.checked;
                                    setRequireReplacementCard(checked);
                                }}/>
                        </Form.Group>
                    </div>
                </div>
                <div className={`row ${!requireReplacementCard ? 'd-none' : ''}`}>
                    <div className="col-md-6">
                        <Form.Group controlId="fuelcard_replacement_reason">
                            <Form.Label>Reason for Replacement</Form.Label>
                            <Form.Control
                                name="fuelcard_replacement_reason"
                                ref={register}
                                isInvalid={!!errors.fuelcard_replacement_reason}
                                as="select"
                            >
                                <option value="">Select...</option>
                                <option value="Lost">Lost</option>
                                <option value="Stolen">Stolen</option>
                                <option value="Worn out">Worn out</option>
                                <option value="Damaged">Damaged</option>
                            </Form.Control>
                            {
                                !!errors.fuelcard_replacement_reason &&
                                <Form.Control.Feedback type="invalid">
                                    {errors.fuelcard_replacement_reason.message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="fuelcard_update_details">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="fuelcard_update_details"
                                ref={register}
                                label="Do you need to update your card details?"
                                onClick={(e) => {
                                    const checked = e.target.checked;
                                    setRequireUpdateCardDetails(checked);
                                }}/>
                        </Form.Group>
                    </div>
                </div>
            </div>
            {
                requireUpdateCardDetails &&
                <div className="section">
                    <h2>Update Card Details</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group controlId={`fuelcard_change_type`}>
                                <Form.Label>Card Type</Form.Label>
                                <Form.Control
                                    name={`fuelcard_change_type`}
                                    ref={register}
                                    isInvalid={!!errors[`fuelcard_change_type`]}
                                    as="select"
                                    onChange={() => {
                                        const values = getValues();
                                        setFuelCardType(values[`fuelcard_change_type`]);
                                    }}
                                >
                                    <option value="">Select...</option>
                                    <option value="Personal">Personal</option>
                                    <option value="Vehicle">Vehicle</option>
                                </Form.Control>
                                {
                                    !!errors[`fuelcard_change_type`] &&
                                    <Form.Control.Feedback type="invalid">
                                        {errors[`fuelcard_change_type`].message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className={`row ${fuelCardType === "Personal" ? "" : "d-none"}`}>
                        <div className="col-md-6">
                            <FormField label="Name on Card" name={`fuelcard_change_name`} ref={register}
                                       id={`fuelcard_change_name`} required={true} type="text"
                                       error={errors[`fuelcard_change_name`]}/>
                        </div>
                    </div>
                    <div className={`row ${fuelCardType === "Vehicle" ? "" : "d-none"}`}>
                        <div className="col-md-6">
                            <FormField label="Vehicle Registration Number" name={`fuelcard_change_vehicle_rego`} ref={register}
                                       id={`fuelcard_change_vehicle_rego`} required={true} type="text"
                                       error={errors[`fuelcard_change_vehicle_rego`]}/>
                        </div>
                        <div className="col-md-6">
                            <FormField label="Vehicle Description" name={`fuelcard_change_vehicle_description`} ref={register}
                                       id={`fuelcard_change_vehicle_description`} required={true} type="text"
                                       error={errors[`fuelcard_change_vehicle_description`]}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormField label="Card PIN (4 digit)" maxLength={4} name={`fuelcard_change_pin`} ref={register}
                                       id={`fuelcard_change_pin`} required={true} type="number"
                                       error={errors[`fuelcard_change_pin`]}/>
                        </div>
                        <div className="col-md-6">
                            <FormField label="Confirm PIN" maxLength={4} name={`fuelcard_change_pin_confirm`} ref={register}
                                       id={`fuelcard_change_pin_confirm`} required={true} type="number"
                                       error={errors[`fuelcard_change_pin_confirm`]}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group controlId={`fuelcard_change_purchase_details`}>
                                <Form.Label>Purchase Details</Form.Label>
                                <Form.Control
                                    name={`fuelcard_change_purchase_details`}
                                    ref={register}
                                    isInvalid={!!errors[`fuelcard_change_purchase_details`]}
                                    as="select"
                                >
                                    <option value="">Select...</option>
                                    {
                                        purchaseDetails.map((item, i) =>
                                            <option key={`fuelcard_change_purchase_details_${i}`}
                                                    value={item}>{item}</option>)
                                    }
                                </Form.Control>
                                {
                                    !!errors[`fuelcard_change_purchase_details`] &&
                                    <Form.Control.Feedback type="invalid">
                                        {errors[`fuelcard_change_purchase_details`].message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group controlId={`fuelcard_change_transaction_limit`}>
                                <Form.Label>Transaction Limit (Daily)</Form.Label>
                                <Form.Control
                                    name={`fuelcard_change_transaction_limit`}
                                    ref={register}
                                    isInvalid={!!errors[`fuelcard_change_transaction_limit`]}
                                    as="select"
                                >
                                    <option value="">Select...</option>
                                    {
                                        transactionLimits.map((item, i) =>
                                            <option key={`fuelcard_change_transaction_limit_${i}`}
                                                    value={item}>{item}</option>)
                                    }
                                </Form.Control>
                                {
                                    !!errors[`fuelcard_change_transaction_limit`] &&
                                    <Form.Control.Feedback type="invalid">
                                        {errors[`fuelcard_change_transaction_limit`].message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Group controlId={`fuelcard_change_bpme_app`}>
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    name={`fuelcard_change_bpme_app`}
                                    ref={register}
                                    label="BPMe Phone App"/>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            }
            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/update-fuelcard/account"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Submit</FormButton>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Fuelcard;
