import React from 'react';
import Header from '../Header';
import ContentContainer from "../ContentContainer";
import Footer from "../Footer";

import './styles.scss';

const MainLayout = () => {
    return (
        <>
            <main className="main-layout">
                <Header />
                <ContentContainer />
                <Footer />
            </main>
        </>
    );
};

export default MainLayout;
