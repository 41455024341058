import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import get from "lodash/get";
import omit from "lodash/omit";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormFuelcardDetails from "../../../components/forms/FormFuelcardDetails";
import {FuelcardSchemaIndividual} from "../../../util/fuelcards";

const FuelcardSchema = FuelcardSchemaIndividual('applicant');

const Fuelcard = () => {
    const {register, handleSubmit, errors, setError, setValue, getValues} = useForm({validationSchema: FuelcardSchema});
    const {savedFormValues, updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [fuelCardType1, setFuelCardType1] = useState("Personal");
    const [fuelCardType2, setFuelCardType2] = useState("Personal");

    const onSubmit = async data => {
        submitFormStep({data: omit(data, "additional_applicant"), step: 3, url: "/individual/directdebit", setError});
    };

    useEffect(() => {
        setValue("additional_applicant", get(savedFormValues, "additional_applicant", false));
    }, [savedFormValues, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(3, "/individual");
        const values = loadSavedFormValues(setValue);

        if (values["applicant_1_fuelcard_type"]) setFuelCardType1(values["applicant_1_fuelcard_type"]);
        if (values["applicant_2_fuelcard_type"]) setFuelCardType2(values["applicant_2_fuelcard_type"]);

        if (values["applicant_1_fuelcard_pin"]) setValue('applicant_1_fuelcard_pin_confirm', values["applicant_1_fuelcard_pin"]);
        if (values["applicant_2_fuelcard_pin"]) setValue('applicant_2_fuelcard_pin_confirm', values["applicant_2_fuelcard_pin"]);

    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors} />

            <FormFuelcardDetails fieldPrefix={'applicant_1'}
                                 applicantName={get(savedFormValues, "applicant_1_name")}
                                 errors={errors}
                                 fuelCardType={fuelCardType1}
                                 setFuelCardType={setFuelCardType1}
                                 getValues={getValues}
                                 register={register} />


            {
                savedFormValues.additional_applicant &&
                <FormFuelcardDetails fieldPrefix={'applicant_2'}
                                     applicantName={get(savedFormValues, "applicant_2_name")}
                                     errors={errors}
                                     fuelCardType={fuelCardType2}
                                     setFuelCardType={setFuelCardType2}
                                     getValues={getValues}
                                     register={register} />
            }

            <div className="section">
                <input name="additional_applicant" type="hidden" ref={register} />
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/individual/references"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Fuelcard;
