import React from "react";
import PropTypes from "prop-types";
import {Col, Form, Row} from 'react-bootstrap';
import FormField from "../FormField";

import './styles.scss';

const FormAircraftDetails = ({fieldPrefix, fuelType, errors, register, aircraftQuantity, setAircraftQuantity}) => {
    const aircraft = () => {
        const aircraftArray = [];
        for (let i = 1; i <= aircraftQuantity; i++) {
            aircraftArray.push(i);
        }
        return aircraftArray;
    }

    return (
        <div className="section">
            <Row>
                <Col xs="12">
                    <h2>{fuelType} only</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`${fieldPrefix}_aircraft_quantity`}>
                        <Form.Label>How many aircraft do you need to register for this fuel type?</Form.Label>
                        <Form.Control
                            name={`${fieldPrefix}_aircraft_quantity`}
                            ref={register}
                            onChange={(e) => setAircraftQuantity(e.target.value)}
                            as="select"
                        >
                            <option value="0">None</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            {
                aircraft().map(item => (
                    <Row key={`${fieldPrefix}_aircraft_${item}`}>
                        <Col>
                            <FormField
                                label={`Aircraft ${item} Registration`}
                                name={`${fieldPrefix}_aircraft_${item}_registration`}
                                id={`${fieldPrefix}_aircraft_${item}_registration`}
                                ref={register}
                                type="text"
                                error={errors[`${fieldPrefix}_aircraft_${item}_registration`]}
                            />
                        </Col>
                        <Col>
                            <FormField
                                label={`Aircraft ${item} Type`}
                                name={`${fieldPrefix}_aircraft_${item}_type`}
                                id={`${fieldPrefix}_aircraft_${item}_type`}
                                ref={register}
                                type="text"
                                error={errors[`${fieldPrefix}_aircraft_${item}_type`]}
                            />
                        </Col>
                        <Col>
                            <FormField
                                label={`Aircraft ${item} Fuel Grade Confirmation`}
                                name={`${fieldPrefix}_aircraft_${item}_fuel_grade`}
                                id={`${fieldPrefix}_aircraft_${item}_fuel_grade`}
                                ref={register}
                                type="text"
                                error={errors[`${fieldPrefix}_aircraft_${item}_fuel_grade`]}
                            />
                        </Col>
                    </Row>
                ))
            }

        </div>
    );
};

FormAircraftDetails.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    fuelType: PropTypes.string.isRequired,
    errors: PropTypes.object,
    register: PropTypes.func,
    aircraftQuantity: PropTypes.string,
    setAircraftQuantity: PropTypes.func
};

export default FormAircraftDetails;
