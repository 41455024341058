import React from "react";
import PropTypes from 'prop-types';
import {Alert} from "react-bootstrap";
import isEmpty from "lodash/isEmpty";

import './styles.scss';

const FormErrorsAlert = ({errors}) => {
    if (isEmpty(errors)) return null;

    const errorsArray = [];
    for (const error in errors) {
        if (errors.hasOwnProperty(error)) errorsArray.push(errors[error].message);
    }

    return (
        <div className="section">
            <Alert variant="danger">
                Please correct the errors below continuing.
            </Alert>
        </div>
    );
};

FormErrorsAlert.propTypes = {
    errors: PropTypes.object
};

export default FormErrorsAlert;
