import React, {useCallback, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import TermsModal from "../../../components/modals/TermsModal";
import get from "lodash/get";
import FormField from "../../../components/forms/FormField";
import {hasFuelcardBpmeApp} from "../../../util/fuelcards";
import BpmeTermsModal from "../../../components/modals/BpmeTermsModal";
import FormButton from "../../../components/forms/FormButton";


const SubmitSchema = yup.object().shape({
    referral_source: yup.string().required("You must specify how you heard about us"),
    referral_source_sales_rep_name: yup.string(),
    referral_source_other_details: yup.string()
        .when("referral_source", (referral_source, schema) => referral_source === 'Other' ? schema.required("Please provide details about how you heard about us") : schema),
    accept_terms: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    require_bpme_terms: yup.boolean(),
    accept_bpme_terms: yup.boolean()
        .when(
            "require_bpme_terms",
            (require_bpme_terms, schema) => require_bpme_terms ? schema.oneOf([true], "You must accept the terms and conditions") : schema
        )
});

const Submit = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: SubmitSchema});
    const {submitFormStep, updateFormStep, loadSavedFormValues, submitFormStepLoading} = useFormManager();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [bpmeTermsModalOpen, setBpmeTermsModalOpen] = useState(false);
    const [referralSource, setReferralSource] = useState(null);

    const acceptTerms = useCallback(() => {
        setValue("accept_terms", true);
    }, [setValue]);

    const acceptBpmeTerms = useCallback(() => {
        setValue("accept_bpme_terms", true);
    }, [setValue]);

    const onSubmit = async data => {
        submitFormStep({data, step: 5, url: "/individual/complete", setError});
    };

    useEffect(() => {
        updateFormStep(5, "/individual");
        const values = loadSavedFormValues(setValue);

        register('require_bpme_terms');
        setValue('require_bpme_terms', hasFuelcardBpmeApp(values));
        setReferralSource(get(values, "referral_source", null));
    }, [loadSavedFormValues, register, setValue, updateFormStep]);

    const requireBpmeTerms = watch('require_bpme_terms');

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>

            <div className="section">
                <h2>How did you hear about us?</h2>

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group>
                            {
                                [
                                    "Word of mouth",
                                    "RD Petroleum website",
                                    "Sales representative",
                                    "Newspaper advertising",
                                    "Printed flier",
                                    "Radio",
                                    "Other"
                                ].map((item, i) => (
                                    <Form.Check
                                        key={`referral_source_${i}`}
                                        name="referral_source"
                                        id={`referral_source_${i}`}
                                        isInvalid={!!errors.referral_source}
                                        feedback={i === 6 && !!errors.referral_source ? errors.referral_source.message : ""}
                                        type="radio"
                                        ref={register}
                                        value={item}
                                        label={item}
                                        onClick={(e) => setReferralSource(e.target.value)}
                                        custom />
                                ))
                            }
                        </Form.Group>
                    </div>
                </div>

                {
                    referralSource === 'Other' &&
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <FormField label="Details"
                                           name="referral_source_other_details"
                                           ref={register}
                                           id="referral_source_other_details"
                                           error={errors.referral_source_other_details}
                                           type="text" />
                            </div>
                        </div>
                    </>
                }

                {
                    referralSource === 'Sales representative' &&
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="mb-3">If you know the name of the sales representative that helped you, please enter it here:</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <FormField label="Sales Representative"
                                               name="referral_source_sales_rep_name"
                                               ref={register}
                                               id="referral_source_sales_rep_name"
                                               type="text" />
                                </div>
                            </div>
                        </>
                }
            </div>

            <div className="section">
                <h2>Terms and Conditions</h2>

                <div className="row">
                    <div className="col-md-12">
                        <p>
                            To complete your application, you must read and accept the <span className="link" onClick={() => setTermsModalOpen(true)}>RD Petroleum Terms &amp; Conditions</span>
                        </p>
                        <Form.Group controlId="accept_terms">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="accept_terms"
                                ref={register}
                                label="I have read and accept the terms and conditions"
                                feedback={!!errors.accept_terms ? errors.accept_terms.message : ""}
                                isInvalid={!!errors.accept_terms}/>
                        </Form.Group>

                    </div>
                </div>

                <TermsModal type="individual" show={termsModalOpen} handleClose={() => setTermsModalOpen(false)} acceptTerms={acceptTerms}/>

                {
                    requireBpmeTerms &&
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    You've indicated that you would like to take advantage of the BPMe app.
                                    Please confirm that you agree to the <span className="link" onClick={() => setBpmeTermsModalOpen(true)}>BPMe Terms of Use</span>
                                </p>
                                <Form.Group controlId="accept_bpme_terms">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        name="accept_bpme_terms"
                                        ref={register}
                                        label="I have read and accept the BPMe terms of use"
                                        feedback={!!errors.accept_bpme_terms ? errors.accept_bpme_terms.message : ""}
                                        isInvalid={!!errors.accept_bpme_terms}/>
                                </Form.Group>

                            </div>
                        </div>
                }

                <BpmeTermsModal show={bpmeTermsModalOpen} handleClose={() => setBpmeTermsModalOpen(false)} acceptTerms={acceptBpmeTerms}/>
            </div>

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button as={Link} to="/individual/directdebit" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Submit Application</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Submit;
