import React, {useCallback, useEffect, useState} from "react";
import get from "lodash/get";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import InputMask from 'react-input-mask';
import FormButton from "../../../components/forms/FormButton";
import DirectDebitModal from "../../../components/modals/DirectDebitModal";

import "./styles.scss";

const PaymentSchema = yup.object().shape({
    // payment_type: yup.string().required('You must select a payment type'),
    direct_debit_bank_name: yup.string().required("You must enter the name of your bank"),
    direct_debit_bank_branch: yup.string().required("You must enter the branch of your bank"),
    direct_debit_bank_account_name: yup.string().required("You must enter the name of your bank account"),
    direct_debit_account_number: yup.string().matches(/^[0-9]{15,16}$/, "Account number: Bank account number is invalid, must be 15 or 16 digits"),
    direct_debit_bank_address: yup.string().required("You must enter the branch of your bank"),
    accept_direct_debit_authority: yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

const Payment = () => {
    const {register, handleSubmit, errors, setError, setValue} = useForm({validationSchema: PaymentSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [directDebitModalOpen, setDirectDebitModalOpen] = useState(false);

    const [paymentType, setPaymentType] = useState('direct_debit');

    const acceptDirectDebitAuthority = useCallback(() => {
        setValue("accept_direct_debit_authority", true);
    }, [setValue]);

    const onSubmit = async data => {
        submitFormStep({data, step: 3, url: "/home-heating/submit", setError});
    };

    const onAccountNumberChange = useCallback((e) => {
        setValue("direct_debit_account_number", e.target.value.replace(/[^\d]+/g, ''));
    }, [setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(3, "/home-heating");
        const savedValues = loadSavedFormValues(setValue);
        setPaymentType(get(savedValues, "payment_type", ""));
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>
            <Form.Control name="payment_type" value="direct_debit" disabled style={{display: 'none'}} type="hidden" />

            {/*<div className="section">
                <h2>Direct Debit Authority</h2>

                <p>
                    RD Petroleum currently accepts payment by direct debit or 7 day invoice. If choosing 7 day invoice, payment is due within 7 days of receiving an invoice. If choosing direct debit, payment will be taken automatically 7 days after invoice date.
                </p>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId="payment_type">
                            <Form.Label>Payment Type</Form.Label>
                            <Form.Control
                                name="payment_type"
                                ref={register}
                                isInvalid={!!errors.payment_type}
                                as="select"
                                onChange={(e) => setPaymentType(e.target.value)}
                            >
                                <option value="">Select...</option>
                                <option value="direct_debit">Direct Debit</option>
                                <option value="10_day_invoice">7 Day Invoice</option>
                            </Form.Control>
                            {
                                !!errors.payment_type &&
                                <Form.Control.Feedback type="invalid">
                                    {errors.payment_type.message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                </div>
            </div>*/}

            {/*{
                paymentType === 'direct_debit' ?
                    <>*/}
                        <div className="section">
                            <h2>Direct Debit Authority</h2>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField
                                        label="Name of Bank"
                                        name="direct_debit_bank_name"
                                        ref={register}
                                        id="direct_debit_bank_name"
                                        type="text"
                                        required={true}
                                        error={errors.direct_debit_bank_name}/>
                                </div>
                                <div className="col-md-6">
                                    <FormField
                                        label="Bank Address"
                                        name="direct_debit_bank_address"
                                        ref={register}
                                        id="direct_debit_bank_address"
                                        type="text"
                                        required={true}
                                        error={errors.direct_debit_bank_address}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField
                                        label="Name of Bank Branch"
                                        name="direct_debit_bank_branch"
                                        ref={register}
                                        id="direct_debit_bank_branch"
                                        type="text"
                                        required={true}
                                        error={errors.direct_debit_bank_branch}/>
                                </div>
                                <div className="col-md-6">
                                    <FormField
                                        label="Name of Bank Account"
                                        name="direct_debit_bank_account_name"
                                        ref={register}
                                        id="direct_debit_bank_account_name"
                                        type="text"
                                        required={true}
                                        error={errors.direct_debit_bank_account_name}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <Form.Group controlId="direct_debit_account_number">
                                        <Form.Label>Bank Account Number <span className="form-required">*</span></Form.Label>

                                        <InputMask
                                            mask="99-9999-9999999-999"
                                            maskChar=" "
                                            onChange={onAccountNumberChange}
                                            className={`form-control ${errors.direct_debit_account_number ? 'is-invalid' : ''}`} />

                                        <input
                                            type="hidden"
                                            name="direct_debit_account_number"
                                            ref={register} />

                                        {
                                            errors.direct_debit_account_number &&
                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                {errors.direct_debit_account_number.message}
                                            </Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <h2>Conditions of This Authority</h2>

                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        You must read and accept the <span className="link"
                                                                           onClick={() => setDirectDebitModalOpen(true)}>Direct Debit Authority Conditions</span>
                                    </p>
                                    <Form.Group controlId="accept_direct_debit_terms">
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            name="accept_direct_debit_authority"
                                            ref={register}
                                            label="I have read and accept the Direct Debit Authority Conditions"
                                            feedback={!!errors.accept_direct_debit_authority ? errors.accept_direct_debit_authority.message : ""}
                                            isInvalid={!!errors.accept_direct_debit_authority}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <DirectDebitModal show={directDebitModalOpen} handleClose={() => setDirectDebitModalOpen(false)}
                                              acceptDirectDebitAuthority={acceptDirectDebitAuthority}/>
                        </div>
                    {/*</>
                    : null
            }*/}


            <div className="section">
                <input name="additional_applicant" type="hidden" ref={register}/>
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/home-heating/delivery-details" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Payment;
