import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import fill from "lodash/fill";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormFuelcardDetails from "../../../components/forms/FormFuelcardDetails";
import {useEffectOnce} from "react-use";
import {FuelcardSchemaBusiness} from "../../../util/fuelcards";

const FuelcardSchema = FuelcardSchemaBusiness();

const Fuelcard = () => {
    const {register, handleSubmit, errors, setError, setValue, getValues} = useForm({validationSchema: FuelcardSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [fuelCardTypes, setFuelCardTypes] = useState(fill(new Array(5), "Personal"));

    const setFuelCardType = useCallback((fuelCardTypes, index, value) => {
        const update = [...fuelCardTypes];
        update.splice(index, 1, value);
        setFuelCardTypes(update);
    }, []);

    const [fuelCardQuantity, setFuelCardQuantity] = useState('');

    const onSubmit = async data => {
        submitFormStep({data, step: 3, url: "/business/directdebit", setError});
    };

    const fuelCardArray = useMemo(() => {
        if (Number(fuelCardQuantity) < 1) return [];
        const array = [];
        for (let i = 1; i <= fuelCardQuantity; i++) array.push(i);
        return array;
    }, [fuelCardQuantity]);

    const [initialLoad, setInitialLoad] = useState(true);

    useEffectOnce(() => {
        runScrollToTop();
        updateFormStep(3, "/business");
        const values = loadSavedFormValues(setValue);
        setFuelCardQuantity(get(values, 'fuelcard_quantity', 0));

        for (let i = 1; i <= 5; i++) {
            if (values[`fuelcard_${i}_fuelcard_type`]) {
                setFuelCardType(fuelCardTypes, i - 1, values[`fuelcard_${i}_fuelcard_type`]);

                if (values[`fuelcard_${i}_fuelcard_pin`]) setValue(`fuelcard_${i}_fuelcard_pin_confirm`, values[`fuelcard_${i}_fuelcard_pin`]);
            }


        }

        setInitialLoad(false);
    });

    useEffect(() => {
        if (!initialLoad) loadSavedFormValues(setValue);
    }, [initialLoad, loadSavedFormValues, setValue]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors} />

            <div className="section">
                <h2>Fuelcards Required</h2>

                <Alert variant="warning">
                    You can apply for up to five fuelcards using this form. If your business requires more than
                    five cards, please contact us after submitting your application.
                </Alert>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId="fuelcard_quantity">
                            <Form.Label>How many fuelcards does your business require?</Form.Label>
                            <Form.Control
                                name="fuelcard_quantity"
                                ref={register}
                                isInvalid={!!errors['fuelcard_quantity']}
                                onChange={(e) => setFuelCardQuantity(e.target.value)}
                                as="select"
                            >
                                <option value="0">Select...</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </Form.Control>
                            {
                                !!errors['fuelcard_quantity'] &&
                                <Form.Control.Feedback type="invalid">
                                    {errors['fuelcard_quantity'].message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                </div>
            </div>

            {
                !isEmpty(fuelCardArray) &&
                fuelCardArray.map((item, i) => (
                    <FormFuelcardDetails key={`fuelcard_${item}`}
                                         fieldPrefix={`fuelcard_${item}`}
                                         applicantName={`Fuelcard ${item}`}
                                         errors={errors}
                                         fuelCardType={fuelCardTypes[i]}
                                         setFuelCardType={(value) => setFuelCardType(fuelCardTypes, i, value)}
                                         allowAllPurchases={false}
                                         getValues={getValues}
                                         register={register} />
                ))
            }

            <div className="section">
                <input name="additional_applicant" type="hidden" ref={register} />
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/business/guarantors"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Fuelcard;
