import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocalStorage, useSessionStorage} from "react-use";
import {useHistory} from "react-router-dom";
import defaultTo from "lodash/defaultTo";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import axios from 'axios';

const requestHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'X-API-KEY': process.env.REACT_APP_API_KEY,
    'Accept': 'application/x.rdp.v1+json'
};

const formManagerContext = createContext(null);

export const ProvideFormManager = ({formID, children}) => {
    const formManager = useProvideFormManager(formID);
    return <formManagerContext.Provider value={formManager}>{children}</formManagerContext.Provider>;
};

ProvideFormManager.propTypes = {
    formID: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export const useFormManager = () => {
    return useContext(formManagerContext);
};

export const retrieveFormFromUUID = (formID, uuid) => {
    return new Promise((resolve, reject) => {
        axios.request({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/forms/${formID}/retrieve`,
            headers: requestHeaders,
            data: {uuid}
        })
        .then(res => {
            resolve(res.data.data);
        })
        .catch(err => reject(err));
    });
};

function useProvideFormManager(formID) {
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState(0);
    const [submitFormStepLoading, setSubmitFormStepLoading] = useState(false);
    const initialFormState = {step: 0};

    const [uuid, setUUID] = useLocalStorage(`${formID}-uuid`);
    const [savedFormValues, setSavedFormValues] = useSessionStorage(formID, initialFormState);

    useEffect(() => {
        if (!uuid && currentStep > 0) {
            history.push('/' + formID);
        }
    }, [uuid, currentStep, history, formID]);

    const updateFormStep = useCallback((step, backURL) => {
        if (step > 1 && step - 1 > savedFormValues.step) {
            history.push(backURL);
            return;
        }
        setCurrentStep(step);
    }, [savedFormValues.step, history]);


    const loadSavedFormValues = useCallback((setValue) => {
        const values = defaultTo(savedFormValues, {});
        if (!isEmpty(values)) {
            for (const key in values) {
                if (values.hasOwnProperty(key)) setValue(key, values[key]);
            }
        }
        return values;
    }, [savedFormValues]);


    const completeForm = useCallback(() => {
        setSavedFormValues(initialFormState);
        setCurrentStep(0);
        setUUID(undefined);
    }, [setSavedFormValues, initialFormState, setUUID]);


    const updateFormValues = useCallback((values = {}) => {
        let newValues = {...savedFormValues, ...values};
        setSavedFormValues(newValues);
    }, [savedFormValues, setSavedFormValues]);


    const setupForm = useCallback(() => (
        new Promise((resolve, reject) => {
            setSavedFormValues(initialFormState);
            setUUID(undefined);

            if (process.env.REACT_APP_SKIP_API !== 'true') {
                axios.request({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE_URL}/forms/${formID}`,
                    headers: requestHeaders
                })
                    .then(res => {
                        setUUID(res.data.data.uuid);
                        resolve(true);
                    })
                    .catch(err => reject(err));
            } else {
                setUUID(`test-uuid-${formID}`);
                resolve(true);
            }

        })), [formID, setUUID, initialFormState, setSavedFormValues]);


    const submitFormStep = useCallback(({data, step, url, setError, manipulate}) => {

        setSubmitFormStepLoading(true);

        if (manipulate) data = {...manipulate(data)};

        if (process.env.REACT_APP_SKIP_API !== 'true') {
            axios.request({
                method: 'put',
                url: `${process.env.REACT_APP_API_BASE_URL}/forms/${formID}`,
                data: {...data, step, uuid},
                headers: requestHeaders
            })
                .then(() => {
                    updateFormValues({...data, step});
                    setSubmitFormStepLoading(false);
                    history.push(url);
                })
                .catch(err => {
                    const errors = get(err.response, 'data.errors', {});
                    for (const error in errors) {
                        if (errors.hasOwnProperty(error)) setError(error, 'error', errors[error][0]);
                    }
                    setSubmitFormStepLoading(false);
                });
        } else {
            updateFormValues({...data, step});
            setSubmitFormStepLoading(false);
            history.push(url);
        }

    }, [uuid, updateFormValues, formID, history]);

    return {
        updateFormStep,
        savedFormValues,
        completeForm,
        updateFormValues,
        currentStep,
        loadSavedFormValues,
        setupForm,
        submitFormStep,
        submitFormStepLoading
    };
}
