import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {routes} from "../../../routes";

import './styles.scss';

const ContentContainer = () => (
    <div className="content-container">
        <Switch>
            {routes.map((route, i) => <Route key={`route-${i}`} {...route} />)}
        </Switch>
    </div>
);

export default ContentContainer;
