import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import get from "lodash/get";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormFuelcardDetails from "../../../components/forms/FormFuelcardDetails";
import {FuelcardSchemaIndividual} from "../../../util/fuelcards";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import * as yup from "yup";

const FuelcardSchema = FuelcardSchemaIndividual('fuelcard', {
    'delivery_address': yup.string().required("You must select a delivery address"),
});

const Fuelcard = () => {
    const {register, handleSubmit, errors, setError, setValue, getValues, watch} = useForm({validationSchema: FuelcardSchema});
    const {savedFormValues, updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [fuelCardType1, setFuelCardType1] = useState("Personal");
    const [fuelCardType2, setFuelCardType2] = useState("Personal");
    const [additionalCard, setAdditionalCard] = useState(false);

    const onSubmit = async data => {
        submitFormStep({data, step: 2, url: "/new-fuelcard/complete", setError});
    };

    useEffect(() => {
        setValue("additional_fuelcard", get(savedFormValues, "additional_fuelcard", false));
    }, [savedFormValues, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(2, "/new-fuelcard");
        const values = loadSavedFormValues(setValue);

        if (values["fuelcard_1_fuelcard_type"]) setFuelCardType1(values["fuelcard_1_fuelcard_type"]);
        if (values["fuelcard_2_fuelcard_type"]) setFuelCardType2(values["fuelcard_2_fuelcard_type"]);

    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors} />

            <FormFuelcardDetails fieldPrefix={'fuelcard_1'}
                                 errors={errors}
                                 fuelCardType={fuelCardType1}
                                 setFuelCardType={setFuelCardType1}
                                 getValues={getValues}
                                 register={register} />

            <div className="row">
                <div className="col-md-12">
                    <Form.Group controlId="additional_fuelcard">
                        <Form.Check
                            custom
                            type="checkbox"
                            name="additional_fuelcard"
                            ref={register}
                            label="Do you require an additional fuelcard?"
                            onClick={(e) => {
                                const checked = e.target.checked;
                                setAdditionalCard(checked);
                            }} />
                    </Form.Group>
                </div>
            </div>



            {
                additionalCard &&
                <FormFuelcardDetails fieldPrefix={'fuelcard_2'}
                                     errors={errors}
                                     fuelCardType={fuelCardType2}
                                     setFuelCardType={setFuelCardType2}
                                     getValues={getValues}
                                     register={register} />
            }
            <div className="section">
                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Delivery Address"
                            name={`delivery_address`}
                            register={register}
                            id={`delivery_address`}
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={!!errors[`delivery_address`]}/>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/new-fuelcard/account"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Submit Application</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Fuelcard;
