import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

import "./styles.scss";

const BpmeTermsModal = ({show, handleClose, acceptTerms}) => {

    const handleAccept = () => {
        acceptTerms();
        handleClose();
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="terms-modal" size="lg" scrollable>
            <Modal.Header>
                <Modal.Title as="h2">BPMe Terms of Use</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>Please read and agree to the following:</p>

                <ul className="mb-3">
                    <li>
                        By signing the new Terms & Conditions you agree to allow all present and future acquired
                        cards under your account to be available to use the BP mobile app if requested by the card
                        holder.
                    </li>
                    <li>
                        Registering to use the BP mobile app effectively creates a second copy of the card on the
                        phone of the person that registers to use the BP mobile app.
                    </li>
                    <li>
                        When the card is cancelled the registration for the BP mobile app will automatically cancel.
                    </li>
                    <li>
                        If the card is for a person other than the account holder ie an employee, the registration of the
                        BP mobile app must be cancelled in accordance with the Terms & Conditions(clause 8.1) if the
                        card is returned or assigned to another person. If the card is assigned to another person and
                        the BP mobile app is not cancelled purchases can still be made by the person who registered
                        to use the BP mobile app.
                    </li>
                    <li>
                        If you do not wish for some of the cards under your account to be available to use the BP
                        mobile app please send an email to fuelcard@rdp.co.nz including a list of current cards to be
                        disabled.
                    </li>
                </ul>

                <p>
                    I confirm I have read and understood the
                    information on this page.
                </p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleAccept}>Accept Terms</Button>
            </Modal.Footer>
        </Modal>
    );
};

BpmeTermsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    acceptTerms: PropTypes.func.isRequired
};

export default BpmeTermsModal;
