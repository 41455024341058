import React, {useCallback, useMemo, useRef, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";
import Autosuggest from 'react-autosuggest';
import axios from "axios";
import debounce from "lodash/debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-regular-svg-icons/faSpinner";

import './styles.scss';

const FormAddressLookup = ({label, name, id, required, error, setValue, watch, register, applicantOrDirector, extraFieldsName}) => {

    const manualAddressRef = useRef(null);
    const [autoFocusAutosuggest, setAutoFocusAutosuggest] = useState(false);
    const [addressQuery, setAddressQuery] = useState('');
    const [addressLookupError, setAddressLookupError] = useState(null);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [addressLookupSearching, setAddressLookupSearching] = useState(false);
    const [enterAddressManually, setEnterAddressManually] = useState(false);
    const [cachedResults, setCachedResults] = useState([]);
    const [street, setStreet] = useState('');
    const [postCode, setPostCode] = useState('');
    const [suburb, setSuburb] = useState('');
    const [city, setCity] = useState('');
    const formValue = watch(name);
    const allowAddressLookup = useMemo(() => !enterAddressManually && (!formValue || formValue === ''), [enterAddressManually, formValue]);

    const streetFieldName = `${extraFieldsName}_street`
    const suburbFieldName = `${extraFieldsName}_suburb`
    const cityFieldName = `${extraFieldsName}_city`
    const postcodeFieldName = `${extraFieldsName}_postcode`

    console.log(error)

    const onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        setValue(name, suggestionValue);
    };

    const fetchAndUpdateSuggestions = useCallback(debounce((value) => {
        axios.request({
            method: 'get',
            timeout: 3000,
            url: `${process.env.REACT_APP_ADDY_API_URL}?key=${process.env.REACT_APP_ADDY_API_KEY}&max=10&s=${value}`
        })
            .then(res => {
                const matches = res.data.addresses.map(address => address.a);
                setCachedResults([
                    ...cachedResults,
                    {
                        query: value,
                        addresses: matches
                    }
                ]);
                setAddressSuggestions(matches);
            })
            .catch(err => {
                setAddressLookupError(true);
            })
            .finally(() => {
                setAddressLookupSearching(false);
            });
    }, 1000), [cachedResults]);

    const onSuggestionsFetchRequested = ({value}) => {
        if (value !== '' && value.length > 3) {
            setAddressLookupSearching(true);

            const cachedMatches = cachedResults.find(c => c.query === value);

            if (cachedMatches) {
                setAddressSuggestions(cachedMatches.addresses);
                setAddressLookupSearching(false);
            } else {
                fetchAndUpdateSuggestions(value);
            }
        }
    };

    const onSuggestionsClearRequested = () => {
        setAddressSuggestions([]);
    };

    const changeAddress = useCallback(() => {
        setValue(name, '');
        setAutoFocusAutosuggest(true);
        setEnterAddressManually(false);
        setAddressQuery('');
    }, [name, setValue]);

    const handleEnterAddressManually = useCallback(() => {
        setEnterAddressManually(true);
        setTimeout(() => manualAddressRef.current.focus(), 200);
    }, []);

    return (
        <Form.Group controlId={id}>
            <Form.Label>
                {label} {required && <span className="form-required">*</span>}
            </Form.Label>

            <p>Type the first line of your address to start searching</p>

            {!applicantOrDirector &&
            <Form.Control
                name={name}
                type="text"
                placeholder={'Enter address manually'}
                ref={(e) => {
                    register(e);
                    manualAddressRef.current = e;
                }}
                readOnly={!enterAddressManually}
                isInvalid={!!error}
                className={allowAddressLookup ? 'd-none' : ''} /> 
                }
            {applicantOrDirector && !enterAddressManually &&
            <Form.Control
                name={name}
                type="text"
                placeholder={'Enter address manually'}
                ref={(e) => {
                    register(e);
                    manualAddressRef.current = e;
                }}
                readOnly={!enterAddressManually}
                isInvalid={!!error[name]}
                className={allowAddressLookup ? 'd-none' : ''} /> 
                }

            {
                applicantOrDirector && enterAddressManually &&
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Form.Control
                            name={streetFieldName}
                            type="text"
                            placeholder="Street"
                            ref={(e) => {
                                register(e);
                                manualAddressRef.current = e;
                            }}
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                            isInvalid={!!error[streetFieldName]}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Control
                            name={suburbFieldName}
                            type="text"
                            placeholder="Suburb"
                            ref={(e) => {
                                register(e);
                                manualAddressRef.current = e;
                            }}
                            value={suburb}
                            onChange={(e) => setSuburb(e.target.value)}
                            isInvalid={!!error[suburbFieldName]}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Control
                            name={cityFieldName}
                            type="text"
                            placeholder="City"
                            ref={(e) => {
                                register(e);
                                manualAddressRef.current = e;
                            }}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            isInvalid={!!error[cityFieldName]}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Control
                            name={postcodeFieldName}
                            type="text"
                            placeholder="Post Code"
                            ref={(e) => {
                                register(e);
                                manualAddressRef.current = e;
                            }}
                            value={postCode}
                            onChange={(e) => setPostCode(e.target.value)}
                            isInvalid={!!error[postcodeFieldName]}
                        />
                    </div>
                </div>
            }

            {
                !enterAddressManually && !allowAddressLookup &&
                <div className="mt-2"><span onClick={changeAddress} className="link">Change address</span></div>
            }

            {
                enterAddressManually &&
                <div className="mt-2"><span onClick={changeAddress} className="link">Search for address</span></div>
            }

            {
                allowAddressLookup &&
                <div className="autosuggest-container">
                    {/* { applicantOrDirector &&
                    <Form.Control
                        name={name}
                        type="text"
                        placeholder={'Enter address manually'}
                        ref={(e) => {
                            register(e);
                            manualAddressRef.current = e;
                        }}
                        readOnly={!enterAddressManually}
                        isInvalid={!!error}
                        className={allowAddressLookup ? 'd-none' : ''} /> } */}
                    <Autosuggest
                        suggestions={addressSuggestions}
                        onSuggestionSelected={onSuggestionSelected}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={(suggestion) => suggestion}
                        renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                        inputProps={{
                            autoFocus: autoFocusAutosuggest,
                            placeholder: 'Search for your address',
                            value: addressQuery,
                            autoComplete: "nope",
                            onChange: (event, {newValue}) => setAddressQuery(newValue),
                            className: `form-control autosuggest-input ${!!error[name] ? 'is-invalid' : ''}`
                        }}
                    />

                    <div className="autosuggest-searching">
                        {
                            addressLookupSearching &&
                            <FontAwesomeIcon icon={faSpinner} spin/>
                        }
                    </div>
                </div>
            }

            {
                (!!error[streetFieldName] || !!error[suburbFieldName] || !!error[cityFieldName] || !!error[postcodeFieldName]) &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {error[streetFieldName] && <p>{error[streetFieldName].message}</p>}
                    {error[suburbFieldName] && <p>{error[suburbFieldName].message}</p>}
                    {error[cityFieldName] && <p>{error[cityFieldName].message}</p>}
                    {error[postcodeFieldName] && <p>{error[postcodeFieldName].message}</p>}
                </Form.Control.Feedback>
            }
            {
                !!error[name] &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {error[name].message}
                </Form.Control.Feedback>
            }
            {
                !!error &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {error.message}
                </Form.Control.Feedback>
            }

            {
                allowAddressLookup &&
                    <div>
                        {
                            addressLookupError &&
                            <div className="invalid-feedback d-block">
                                The address lookup service appears to be unavailable. Try <span className="link text-danger" style={{'textDecoration': 'underline'}} onClick={handleEnterAddressManually}>entering the address manually</span>.
                            </div>
                        }
                        <div className="mt-2">
                            <span className="link" onClick={handleEnterAddressManually}>Can't find address? Enter it manually</span>
                        </div>
                    </div>
            }
        </Form.Group>
    );
};

FormAddressLookup.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired
};

FormAddressLookup.defaultProps = {
    required: false
};

export default FormAddressLookup;
