import React, {useEffect, useState} from "react";
import {retrieveFormFromUUID} from "../../util/form-manager";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {Alert} from "react-bootstrap";
import {useLocalStorage, useSessionStorage} from "react-use";

const RetrievePage = () => {
    const location = useLocation();
    const {formID} = useParams();
    const history = useHistory();
    const [settingUp, setSettingUp] = useState(true);
    const [settingUpError, setSettingUpError] = useState(null);
    const [uuid, setUUID] = useLocalStorage(`${formID}-uuid`);
    const [, setSavedFormValues] = useSessionStorage(formID, {step: 0});

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const uuid = params.get('uuid');

        retrieveFormFromUUID(formID, uuid)
            .then(data => {
                setUUID(uuid);
                setSavedFormValues({...data, step: 1});
                history.push(`/${formID}/account`);
            })
            .catch(err => {
                setSettingUpError(true);
                setSettingUp(false);
            });

    }, [history, location.search, formID, uuid, setSavedFormValues, setUUID]);

    return (
        <div className="container-fluid">
            <div className="section">
                {
                    settingUp ?
                        <p>Retrieving form details, please wait...</p>
                        :
                        settingUpError ?
                            <Alert variant="danger">
                                Sorry, it looks like this form ID is invalid.
                                Please <Link to="/">return to the home page</Link>.
                            </Alert>
                            :
                            <p>Loading form, please wait...</p>
                }
            </div>
        </div>
    );

};

export default RetrievePage;
