import React from "react";
import PropTypes from "prop-types";
import {Form} from 'react-bootstrap';
import FormField from "../FormField";

import './styles.scss';

const FormFuelcardDetails = ({fieldPrefix, applicantName, fuelCardType, setFuelCardType, allowAllPurchases, errors, getValues, register}) => {

    const purchaseDetails = [
        "Fuel only",
        "Fuel and oil only",
        "Fuel, oil and wash",
        "Diesel only",
        "Diesel and oil only"
    ];

    if (allowAllPurchases) purchaseDetails.push('All purchases');

    const transactionLimits = [
        "$100",
        "$150",
        "$200",
        "$250",
        "$400",
        "$500",
        "$1000",
        "$2000",
        "$5000"
    ];

    return (
        <div className="section">
            <h2>Card Details {applicantName ? `- ${applicantName}` : ''}</h2>

            <div className="row">
                <div className="col-md-6">
                    <Form.Group controlId={`${fieldPrefix}_fuelcard_type`}>
                        <Form.Label>Card Type</Form.Label>
                        <Form.Control
                            name={`${fieldPrefix}_fuelcard_type`}
                            ref={register}
                            isInvalid={!!errors[`${fieldPrefix}_fuelcard_type`]}
                            as="select"
                            onChange={() => {
                                const values = getValues();
                                setFuelCardType(values[`${fieldPrefix}_fuelcard_type`]);
                            }}
                        >
                            <option value="">Select...</option>
                            <option value="Personal">Personal</option>
                            <option value="Vehicle">Vehicle</option>
                        </Form.Control>
                        {
                            !!errors[`${fieldPrefix}_fuelcard_type`] &&
                            <Form.Control.Feedback type="invalid">
                                {errors[`${fieldPrefix}_fuelcard_type`].message}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
            </div>

            <div className={`row ${fuelCardType === "Personal" ? "" : "d-none"}`}>
                <div className="col-md-6">
                    <FormField label="Name on Card" name={`${fieldPrefix}_fuelcard_name`} ref={register}
                               id={`${fieldPrefix}_fuelcard_name`} required={true} type="text"
                               error={errors[`${fieldPrefix}_fuelcard_name`]}/>
                </div>
            </div>

            <div className={`row ${fuelCardType === "Vehicle" ? "" : "d-none"}`}>
                <div className="col-md-6">
                    <FormField label="Vehicle Registration Number" name={`${fieldPrefix}_fuelcard_vehicle_rego`} ref={register}
                               id={`${fieldPrefix}_fuelcard_vehicle_rego`} required={true} type="text"
                               error={errors[`${fieldPrefix}_fuelcard_vehicle_rego`]}/>
                </div>
                <div className="col-md-6">
                    <FormField label="Vehicle Description" name={`${fieldPrefix}_fuelcard_vehicle_description`} ref={register}
                               id={`${fieldPrefix}_fuelcard_vehicle_description`} required={true} type="text"
                               error={errors[`${fieldPrefix}_fuelcard_vehicle_description`]}/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <FormField label="Card PIN (4 digit)" maxLength={4} name={`${fieldPrefix}_fuelcard_pin`} ref={register}
                               id={`${fieldPrefix}_fuelcard_pin`} required={true} type="number"
                               error={errors[`${fieldPrefix}_fuelcard_pin`]}/>
                </div>
                <div className="col-md-6">
                    <FormField label="Confirm PIN" maxLength={4} name={`${fieldPrefix}_fuelcard_pin_confirm`} ref={register}
                               id={`${fieldPrefix}_fuelcard_pin_confirm`} required={true} type="number"
                               error={errors[`${fieldPrefix}_fuelcard_pin_confirm`]}/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <Form.Group controlId={`${fieldPrefix}_fuelcard_purchase_details`}>
                        <Form.Label>Purchase Details</Form.Label>
                        <Form.Control
                            name={`${fieldPrefix}_fuelcard_purchase_details`}
                            ref={register}
                            isInvalid={!!errors[`${fieldPrefix}_fuelcard_purchase_details`]}
                            as="select"
                        >
                            <option value="">Select...</option>
                            {
                                purchaseDetails.map((item, i) => <option key={`${fieldPrefix}_fuelcard_purchase_details_${i}`}
                                                                         value={item}>{item}</option>)
                            }
                        </Form.Control>
                        {
                            !!errors[`${fieldPrefix}_fuelcard_purchase_details`] &&
                            <Form.Control.Feedback type="invalid">
                                {errors[`${fieldPrefix}_fuelcard_purchase_details`].message}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId={`${fieldPrefix}_fuelcard_transaction_limit`}>
                        <Form.Label>Transaction Limit (Daily)</Form.Label>
                        <Form.Control
                            name={`${fieldPrefix}_fuelcard_transaction_limit`}
                            ref={register}
                            isInvalid={!!errors[`${fieldPrefix}_fuelcard_transaction_limit`]}
                            as="select"
                        >
                            <option value="">Select...</option>
                            {
                                transactionLimits.map((item, i) => <option key={`${fieldPrefix}_fuelcard_transaction_limit_${i}`}
                                                                           value={item}>{item}</option>)
                            }
                        </Form.Control>
                        {
                            !!errors[`${fieldPrefix}_fuelcard_transaction_limit`] &&
                            <Form.Control.Feedback type="invalid">
                                {errors[`${fieldPrefix}_fuelcard_transaction_limit`].message}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Form.Group controlId={`${fieldPrefix}_fuelcard_bpme_app`}>
                        <Form.Check
                            custom
                            type="checkbox"
                            name={`${fieldPrefix}_fuelcard_bpme_app`}
                            ref={register}
                            label="BPMe Phone App"/>
                    </Form.Group>
                </div>
            </div>
        </div>
    );
};

FormFuelcardDetails.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    applicantName: PropTypes.string,
    errors: PropTypes.object,
    register: PropTypes.func,
    getValues: PropTypes.func,
    allowAllPurchases: PropTypes.bool,
    fuelCardType: PropTypes.string,
    setFuelCardType: PropTypes.func
};

export default FormFuelcardDetails;
