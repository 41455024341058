import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import DirectDebitTerms from "./DirectDebitTerms";

import "./styles.scss";

const DirectDebitModal = ({show, handleClose, acceptDirectDebitAuthority}) => {

    const handleAccept = () => {
        acceptDirectDebitAuthority();
        handleClose();
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="terms-modal" size="lg" scrollable>
            <Modal.Header>
                <Modal.Title as="h2">Conditions of This Authority</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <DirectDebitTerms/>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleAccept}>Accept</Button>
            </Modal.Footer>
        </Modal>
    );
};

DirectDebitModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    acceptDirectDebitAuthority: PropTypes.func.isRequired
};

export default DirectDebitModal;
