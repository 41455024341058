import React from "react";
import IndexButton from "../../components/buttons/IndexButton";
import {
    faCreditCard,
    faBurn,
    faBuilding,
    faPencil,
    faPlane,
    faPlaneDeparture,
    faPlus,
    faTractor,
    faUser,
    faFileContract,
} from "@fortawesome/pro-duotone-svg-icons";
import IndexButtonExternal from "../../components/buttons/IndexButtonExternal";

const IndexPage = () => (
    <div className="container-fluid">
        <div className="section">
            <h1>RD Petroleum Application Forms</h1>
            <p>
                Apply online for RD Petroleum services using the links below.
            </p>
        </div>

        <div className="section">
            <h2>Fuel Delivery</h2>
            <IndexButton url="/bulk-individual" title="Bulk Fuel Application - Individual / Partnerships" icon={faUser}/>
            <IndexButton url="/bulk-business" title="Bulk Fuel Application - Corporate (Limited Company, Trust and Incorporated Society)" icon={faTractor}/>
            <IndexButton url="/home-heating" title="Home Heating Application" icon={faBurn}/>
        </div>

        <div className="section section-borderless">
            <h2>Aviation Fuelcards</h2>
            <IndexButton url="/individual-aviation" title="Aviation Fuelcard Application - Individual / Partnerships" icon={faPlane}/>
            <IndexButton url="/business-aviation"
                         title="Aviation Fuelcard Application - Corporate (Limited Company, Trust and Incorporated Society)"
                         icon={faPlaneDeparture}/>
        </div>

        <div className="section section-borderless">
            <h2>Fuelcards</h2>
            <IndexButton url="/individual" title="Fuelcard Application - Individual / Partnerships" icon={faUser}/>
            <IndexButton url="/business"
                         title="Fuelcard Application - Corporate (Limited Company, Trust and Incorporated Society)"
                         icon={faBuilding}/>
            <IndexButton url="/new-fuelcard"
                         title="Add New Fuelcard to Account"
                         icon={faCreditCard}
                         icon2={faPlus}
                         icon2Styles={{'right': '6px', 'bottom': '14px'}} />
            <IndexButton url="/update-fuelcard"
                         title="Update Existing Fuelcard Details"
                         icon={faCreditCard}
                         icon2={faPencil}
                         icon2Styles={{'right': '6px', 'bottom': '15px', 'fontSize': '.45rem'}} />
            <IndexButtonExternal url="//eu.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=41d4d6c0-28f4-4b72-9176-6db9722812a0&env=eu&acct=75b8b34e-dcf8-4474-80da-cfd87af4cc15&v=2"
                         title="Update Terms and Conditions"
                         icon={faFileContract}
                         newTab={true} />
        </div>
    </div>
);

export default IndexPage;
