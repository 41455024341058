import React from "react";
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";

const FormField = React.forwardRef(({label, name, id, type, required, error, maxLength}, ref) => {
    return (
        <Form.Group controlId={id}>
            <Form.Label>{label} {required && <span className="form-required">*</span>}</Form.Label>
            <Form.Control
                name={name}
                type={type}
                maxLength={maxLength}
                ref={ref}
                isInvalid={!!error} />
            {
                !!error &&
                <Form.Control.Feedback type="invalid">
                    {error.message}
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
});

FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object
};

FormField.defaultProps = {
    required: false,
    maxLength: 500
};

export default FormField;
