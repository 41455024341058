import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import {FuelCardSchemaAviation} from "../../../util/fuelcards";
import FormAircraftDetails from "../../../components/forms/FormAircraftDetails";
import {useEffectOnce} from "react-use";


const Fuelcard = () => {
    const [avgasAircraftQuantity, setAvgasAircraftQuantity] = useState('');
    const [jeta1AircraftQuantity, setJeta1AircraftQuantity] = useState('');
    const FuelcardSchema = FuelCardSchemaAviation(avgasAircraftQuantity, jeta1AircraftQuantity);
    const {register, handleSubmit, errors, setError, setValue, getValues} = useForm({validationSchema: FuelcardSchema});
    const {savedFormValues, updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const onSubmit = async data => {
        submitFormStep({data: data, step: 3, url: "/individual-aviation/directdebit", setError});
    };

    useEffectOnce(() => {
        runScrollToTop();
    });

    useEffect(() => {
        updateFormStep(3, "/individual-aviation");
        const values = loadSavedFormValues(setValue);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors} />

            <FormAircraftDetails
                fuelType="Avgas"
                fieldPrefix={'avgas'}
                errors={errors}
                getValues={getValues}
                register={register}
                aircraftQuantity={avgasAircraftQuantity}
                setAircraftQuantity={setAvgasAircraftQuantity}
            />

            <FormAircraftDetails
                fuelType="Jet-A1"
                fieldPrefix={'jeta1'}
                errors={errors}
                getValues={getValues}
                register={register}
                aircraftQuantity={jeta1AircraftQuantity}
                setAircraftQuantity={setJeta1AircraftQuantity}
            />

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/individual-aviation/references"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Fuelcard;
