import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import TermsBusiness from "./TermsBusiness";
import TermsIndividual from "./TermsIndividuals";
import TermsHomeHeating from "./HomeHeating";

import "./styles.scss";

const TermsModal = ({type, show, handleClose, acceptTerms}) => {

    const handleAccept = () => {
        acceptTerms();
        handleClose();
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="terms-modal" size="lg" scrollable>
            <Modal.Header>
                <Modal.Title as="h2">Terms &amp; Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'business' && <TermsBusiness />}
                {type === 'individual' && <TermsIndividual />}
                {type === 'home-heating' && <TermsHomeHeating />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleAccept}>Accept Terms</Button>
            </Modal.Footer>
        </Modal>
    );
};

TermsModal.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    acceptTerms: PropTypes.func.isRequired
};

export default TermsModal;
