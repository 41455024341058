import React from 'react';
import { format } from 'date-fns';

import './styles.scss';

const Footer = () => (
    <footer className="footer">
        <div className="footer-inner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md d-flex justify-content-center justify-content-md-start align-items-center flex-column flex-md-row">
                        <div>
                            &copy; RD Petroleum {format(new Date(), 'yyyy')}. <br />
                            Contact us - <a href="tel:0800440014">0800 44 00 14</a>.
                        </div>
                    </div>
                    <div className="col-md-auto d-flex justify-content-center justify-content-md-end align-items-center">
                        <div><a href="https://www.rdp.co.nz" target="_blank" rel="noopener noreferrer">rdp.co.nz</a></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
