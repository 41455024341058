import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import DirectDebitModal from "../../../components/modals/DirectDebitModal";
import InputMask from "react-input-mask";

import "./styles.scss";

const DirectDebitSchema = yup.object().shape({
    direct_debit_bank_name: yup.string().required("You must enter the name of your bank"),
    direct_debit_bank_branch: yup.string().required("You must enter the branch of your bank"),
    direct_debit_bank_account_name: yup.string().required("You must enter the name of your bank account"),

    direct_debit_account_number: yup.string().matches(/^[0-9]{15,16}$/, "Account number: Bank account number is invalid, must be 15 or 16 digits"),

    direct_debit_bank_address: yup.string().required("You must enter the branch of your bank"),

    accept_direct_debit_authority: yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

const DirectDebit = () => {
    const {register, handleSubmit, errors, setError, setValue} = useForm({validationSchema: DirectDebitSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [directDebitModalOpen, setDirectDebitModalOpen] = useState(false);


    const acceptDirectDebitAuthority = useCallback(() => {
        setValue("accept_direct_debit_authority", true);
    }, [setValue]);

    const onSubmit = async data => {
        submitFormStep({data, step: 4, url: "/business/submit", setError});
    };

    const onAccountNumberChange = useCallback((e) => {
        setValue("direct_debit_account_number", e.target.value.replace(/[^\d]+/g, ''));
    }, [setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(4, "/business");
        loadSavedFormValues(setValue);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>

            <div className="section">
                <h2>Direct Debit Authority</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="Name of Bank"
                            name="direct_debit_bank_name"
                            ref={register}
                            id="direct_debit_bank_name"
                            type="text"
                            required={true}
                            error={errors.direct_debit_bank_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Bank Address"
                            name="direct_debit_bank_address"
                            ref={register}
                            id="direct_debit_bank_address"
                            type="text"
                            required={true}
                            error={errors.direct_debit_bank_address}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="Name of Bank Branch"
                            name="direct_debit_bank_branch"
                            ref={register}
                            id="direct_debit_bank_branch"
                            type="text"
                            required={true}
                            error={errors.direct_debit_bank_branch}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Name of Bank Account"
                            name="direct_debit_bank_account_name"
                            ref={register}
                            id="direct_debit_bank_account_name"
                            type="text"
                            required={true}
                            error={errors.direct_debit_bank_account_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <Form.Group controlId="direct_debit_account_number">
                            <Form.Label>Bank Account Number <span className="form-required">*</span></Form.Label>

                            <InputMask
                                mask="99-9999-9999999-999"
                                maskChar=" "
                                onChange={onAccountNumberChange}
                                className={`form-control ${errors.direct_debit_account_number ? 'is-invalid' : ''}`} />

                            <input
                                type="hidden"
                                name="direct_debit_account_number"
                                ref={register} />

                            {
                                errors.direct_debit_account_number &&
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors.direct_debit_account_number.message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                </div>

            </div>

            <div className="section">
                <h2>Conditions of This Authority</h2>

                <div className="row">
                    <div className="col-md-12">
                        <p>
                            You must read and accept the <span className="link"
                                                               onClick={() => setDirectDebitModalOpen(true)}>Direct Debit Authority Conditions</span>
                        </p>
                        <Form.Group controlId="accept_direct_debit_terms">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="accept_direct_debit_authority"
                                ref={register}
                                label="I have read and accept the Direct Debit Authority Conditions"
                                feedback={!!errors.accept_direct_debit_authority ? errors.accept_direct_debit_authority.message : ""}
                                isInvalid={!!errors.accept_direct_debit_authority}/>
                        </Form.Group>
                    </div>
                </div>

                <DirectDebitModal show={directDebitModalOpen} handleClose={() => setDirectDebitModalOpen(false)}
                                  acceptDirectDebitAuthority={acceptDirectDebitAuthority}/>
            </div>

            <div className="section">
                <input name="additional_applicant" type="hidden" ref={register}/>
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/business/fuelcard" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

const BankAccountNumber = ({register, errors}) => {
    return (
        <div className="bank-account-number">
            <div className="bank-account-number-input">
                <div className="bank-account-number-part bank-code">
                    <Form.Label>Bank</Form.Label>
                    <Form.Control
                        name="direct_debit_account_bank_code"
                        type="number"
                        ref={register}
                        isInvalid={errors.direct_debit_account_bank_code}/>
                </div>
                <div className="bank-account-number-part branch-code">
                    <Form.Label>Branch</Form.Label>
                    <Form.Control
                        name="direct_debit_account_branch_number"
                        type="number"
                        ref={register}
                        isInvalid={errors.direct_debit_account_branch_number}/>
                </div>
                <div className="bank-account-number-part account-no">
                    <Form.Label>Account</Form.Label>
                    <Form.Control
                        name="direct_debit_account_account_number"
                        type="number"
                        ref={register}
                        isInvalid={errors.direct_debit_account_account_number}/>
                </div>
                <div className="bank-account-number-part suffix">
                    <Form.Label>Suffix</Form.Label>
                    <Form.Control
                        name="direct_debit_account_suffix"
                        type="number"
                        ref={register}
                        isInvalid={errors.direct_debit_account_suffix}/>
                </div>
            </div>
            {
                errors.direct_debit_account_bank_code &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.direct_debit_account_bank_code.message}
                </Form.Control.Feedback>
            }
            {
                errors.direct_debit_account_branch_number &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.direct_debit_account_branch_number.message}
                </Form.Control.Feedback>
            }
            {
                errors.direct_debit_account_account_number &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.direct_debit_account_account_number.message}
                </Form.Control.Feedback>
            }
            {
                errors.direct_debit_account_suffix &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.direct_debit_account_suffix.message}
                </Form.Control.Feedback>
            }
        </div>
    );
};

BankAccountNumber.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default DirectDebit;
