import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";

const ReferencesSchema = yup.object().shape({
    trade_reference_1_name: yup.string(),
    trade_reference_1_phone: yup.string(),
    trade_reference_2_name: yup.string(),
    trade_reference_2_phone: yup.string(),
    independent_contact_name: yup.string().required("Contact person name is required"),
    independent_contact_address: yup.string().required("Contact person address is required"),
    independent_contact_phone: yup.string().required("Contact person phone is required")
});

const References = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: ReferencesSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const onSubmit = async data => {
        submitFormStep({data, step: 2, url: "/individual-aviation/fuelcard", setError});
    };

    useEffect(() => {
        runScrollToTop();
        updateFormStep(2, "/individual");
        loadSavedFormValues(setValue);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>
            <div className="section">
                <h2>Trade References</h2>

                <p>Trade references are only required for sole traders.</p>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Name (1)" name="trade_reference_1_name" ref={register} id="trade_reference_1_name" type="text"
                                   error={errors.trade_reference_1_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone (1)" name="trade_reference_1_phone" ref={register} id="trade_reference_1_phone" type="text"
                                   error={errors.trade_reference_1_phone}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Name (2)" name="trade_reference_2_name" ref={register} id="trade_reference_2_name" type="text"
                                   error={errors.trade_reference_2_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone (2)" name="trade_reference_2_phone" ref={register} id="trade_reference_2_phone" type="text"
                                   error={errors.trade_reference_2_phone}/>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Independent Contact Person</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="independent_contact_name" ref={register} id="independent_contact_name" type="text" required={true}
                                   error={errors.independent_contact_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone" name="independent_contact_phone" ref={register} id="independent_contact_phone" type="text" required={true}
                                   error={errors.independent_contact_phone}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="independent_contact_address"
                            register={register}
                            id="independent_contact_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={!!errors.independent_contact_address} />
                    </div>
                </div>

            </div>

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/individual-aviation/account" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default References;
