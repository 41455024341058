import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Account from "./Account";
import DeliveryDetails from "./DeliveryDetails";
import Payment from "./Payment";
import Submit from "./Submit";
import Complete from "./Complete";
import Start from "./Start";
import {ProvideFormManager} from "../../util/form-manager";
import FormProgress from "../../components/forms/FormProgress";

import "./styles.scss";

const HomeHeatingPage = () => {

    const route = useRouteMatch();

    const steps = [
        {step: 1, label: "Account"},
        {step: 2, label: "Delivery Details"},
        // {step: 3, label: "Payment"},
        {step: 3, label: "Direct Debit"},
        {step: 4, label: "Submit"}
    ];

    return (
        <ProvideFormManager formID="home-heating">
            <FormProgress steps={steps}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Switch>
                            <Route path={`${route.path}/account`}>
                                <Account/>
                            </Route>
                            <Route path={`${route.path}/delivery-details`}>
                                <DeliveryDetails/>
                            </Route>
                            <Route path={`${route.path}/payment`}>
                                <Payment/>
                            </Route>
                            <Route path={`${route.path}/submit`}>
                                <Submit/>
                            </Route>
                            <Route path={`${route.path}/complete`}>
                                <Complete/>
                            </Route>
                            <Route path={`${route.path}`}>
                                <Start/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </ProvideFormManager>
    );
};

export default HomeHeatingPage;
