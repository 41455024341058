import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import get from "lodash/get";

const tankMessage = "Please enter size of at least one tank";

const DeliverySchema = yup.object().shape({

    premises_type: yup.string().required("Premises type is required"),

    auto_delivery: yup.boolean(),

    delivery_frequency: yup.string().when(
        "auto_delivery",
        (value, schema) => value === true ? schema.required("Delivery frequency is required") : schema
    ),

    delivery_address_1: yup.string().required("Delivery address is required"),
    delivery_instructions_1: yup.string(),
    diesel_tank_1: yup.string().test('dt1', tankMessage, function (value) {
        const {petrol_tank_1, home_heat_tank_1} = this.parent;
        const required = petrol_tank_1 === "" && home_heat_tank_1 === "";
        return !required || value !== '';
    }),
    petrol_tank_1: yup.string().test('pt1', tankMessage, function (value) {
        const {diesel_tank_1, home_heat_tank_1} = this.parent;
        const required = diesel_tank_1 === "" && home_heat_tank_1 === "";
        return !required || value !== '';
    }),
    home_heat_tank_1: yup.string().test('hht1', tankMessage, function (value) {
        const {diesel_tank_1, petrol_tank_1} = this.parent;
        const required = diesel_tank_1 === "" && petrol_tank_1 === "";
        return !required || value !== '';
    }),

    delivery_address_2: yup.string().when(
        "additional_delivery_address",
        (value, schema) => value === true ? schema.required("Delivery address is required") : schema
    ),
    delivery_instructions_2: yup.string(),
    diesel_tank_2: yup.string().when(
        "additional_delivery_address",
        (value, schema) => value === true ? schema.test('dt2', tankMessage, function (value) {
            const {petrol_tank_2, home_heat_tank_2} = this.parent;
            const required = petrol_tank_2 === "" && home_heat_tank_2 === "";
            return !required || value !== '';
        }) : schema
    ),
    petrol_tank_2: yup.string().when(
        "additional_delivery_address",
        (value, schema) => value === true ? schema.test('pt2', tankMessage, function (value) {
            const {diesel_tank_2, home_heat_tank_2} = this.parent;
            const required = diesel_tank_2 === "" && home_heat_tank_2 === "";
            return !required || value !== '';
        }) : schema
    ),
    home_heat_tank_2: yup.string().when(
        "additional_delivery_address",
        (value, schema) => value === true ? schema.test('hht2', tankMessage, function (value) {
            const {diesel_tank_2, petrol_tank_2} = this.parent;
            const required = diesel_tank_2 === "" && petrol_tank_2 === "";
            return !required || value !== '';
        }) : schema
    )
});

const DeliveryDetails = ({number, register, setValue, watch, errors}) => {

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <FormAddressLookup
                        label="Delivery Address"
                        name={`delivery_address_${number}`}
                        register={register}
                        id={`delivery_address_${number}`}
                        required={false}
                        setValue={setValue}
                        watch={watch}
                        error={!!errors[`delivery_address_${number}`]}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group controlId={`delivery_instructions_${number}`}>
                        <Form.Label>Delivery Instructions</Form.Label>
                        <Form.Control as="textarea"
                                      name={`delivery_instructions_${number}`}
                                      ref={register}
                                      isInvalid={!!errors[`delivery_instructions_${number}`]}/>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormField label="Diesel Tank Size"
                               name={`diesel_tank_${number}`}
                               ref={register}
                               id={`diesel_tank_${number}`}
                               type="text"
                               error={errors[`diesel_tank_${number}`]}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormField label="Petrol Tank Size"
                               name={`petrol_tank_${number}`}
                               ref={register}
                               id={`petrol_tank_${number}`}
                               type="text"
                               error={errors[`petrol_tank_${number}`]}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormField label="Home Heat Tank Size"
                               name={`home_heat_tank_${number}`}
                               ref={register}
                               id={`home_heat_tank_${number}`}
                               type="text"
                               error={errors[`home_heat_tank_${number}`]}/>
                </div>
            </div>
        </>
    );

};

const Delivery = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: DeliverySchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const [showDeliveryFrequency, setShowDeliveryFrequency] = useState(false);
    const [showDeliveryDetailsTwo, setShowDeliveryDetailsTwo] = useState(false);

    const onSubmit = async data => {
        submitFormStep({data, step: 3, url: "/bulk-individual/fuelcard", setError});
    };

    useEffect(() => {
        if (!showDeliveryFrequency) {
            setValue("delivery_frequency", undefined);
        }
    }, [showDeliveryFrequency, setValue]);

    useEffect(() => {
        if (!showDeliveryDetailsTwo) {
            setValue("delivery_address_2", undefined);
            setValue("delivery_instructions_2", undefined);
            setValue("diesel_tank_2", undefined);
            setValue("petrol_tank_2", undefined);
            setValue("home_heat_tank_2", undefined);
        }
    }, [showDeliveryDetailsTwo, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(3, "/bulk-individual");
        const values = loadSavedFormValues(setValue);
        if (get(values, "additional_delivery_address", false)) setShowDeliveryDetailsTwo(true);
        if (get(values, "auto_delivery", false)) setShowDeliveryFrequency(true);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>
            <div className="section">
                <h2>Delivery</h2>

                <div className="row">

                    <div className="col-md-6">
                        <Form.Group controlId="premises_type">
                            <Form.Label>Premises</Form.Label>
                            <Form.Control
                                name="premises_type"
                                ref={register}
                                isInvalid={!!errors['premises_type']}
                                as="select">
                                <option value="">Select...</option>
                                <option value="farm">Farm</option>
                                <option value="commercial">Commercial</option>
                                <option value="home-heat">Home Heat</option>
                            </Form.Control>
                            {
                                !!errors['premises_type'] &&
                                <Form.Control.Feedback type="invalid">
                                    {errors['premises_type'].message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">
                        <Form.Group controlId="auto_delivery">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="auto_delivery"
                                ref={register}
                                label="Do you want fuel to be delivered automatically?"
                                onClick={(e) => {
                                    setShowDeliveryFrequency(e.target.checked);
                                }} />
                        </Form.Group>
                    </div>

                </div>

                <div className={`row ${!showDeliveryFrequency ? 'd-none' : ''}`}>

                    <div className="col-md-6">
                        <Form.Group controlId="delivery_frequency">
                            <Form.Label>Delivery Frequency</Form.Label>
                            <Form.Control
                                name="delivery_frequency"
                                ref={register}
                                isInvalid={!!errors['delivery_frequency']}
                                as="select">
                                <option value="">Select...</option>
                                <option value="weekly">Weekly</option>
                                <option value="2 weekly">2 weekly</option>
                                <option value="4 weekly">4 weekly</option>
                                <option value="8 weekly">8 weekly</option>
                            </Form.Control>
                            {
                                !!errors['delivery_frequency'] &&
                                <Form.Control.Feedback type="invalid">
                                    {errors['delivery_frequency'].message}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>

                </div>

            </div>
            <div className="section">
                <h2>Delivery Details 1</h2>
                <DeliveryDetails number={1}
                                 errors={errors}
                                 register={register}
                                 setValue={setValue}
                                 watch={watch}/>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group controlId="additional_delivery_address">
                        <Form.Check
                            custom
                            type="checkbox"
                            name="additional_delivery_address"
                            ref={register}
                            label="Do you require fuel to be delivered to an additional address?"
                            onClick={(e) => {
                                const checked = e.target.checked;
                                setShowDeliveryDetailsTwo(checked);
                            }}/>
                    </Form.Group>
                </div>
            </div>
            <div className={`section ${!showDeliveryDetailsTwo ? "d-none" : ""}`}>
                <h2>Delivery Details 2</h2>
                <DeliveryDetails number={2}
                                 errors={errors}
                                 register={register}
                                 setValue={setValue}
                                 watch={watch}/>
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/bulk-individual/references" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Delivery;
