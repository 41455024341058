import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Account from "./Account";
import Fuelcard from "./Fuelcard";
import Complete from "./Complete";
import Start from "./Start";
import {ProvideFormManager} from "../../util/form-manager";
import FormProgress from "../../components/forms/FormProgress";

import "./styles.scss";

const NewFuelcardPage = () => {

    const route = useRouteMatch();

    const steps = [
        {step: 1, label: "Account"},
        {step: 2, label: "Fuelcard"}
    ];

    return (
        <ProvideFormManager formID="new-fuelcard">

            <FormProgress steps={steps} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Switch>
                            <Route path={`${route.path}/account`}>
                                <Account />
                            </Route>
                            <Route path={`${route.path}/fuelcard`}>
                                <Fuelcard />
                            </Route>
                            <Route path={`${route.path}/complete`}>
                                <Complete />
                            </Route>
                            <Route path={`${route.path}`}>
                                <Start />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </ProvideFormManager>
    );
};

export default NewFuelcardPage;
