import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Account from "./Account";
import Fuelcard from "./Fuelcard";
import DirectDebit from "./DirectDebit";
import Submit from "./Submit";
import Complete from "./Complete";
import Start from "./Start";
import {ProvideFormManager} from "../../util/form-manager";
import FormProgress from "../../components/forms/FormProgress";
import Guarantors from "./Guarantors";

import "./styles.scss";

const NewBusinessPage = () => {

    const route = useRouteMatch();

    const steps = [
        {step: 1, label: "Account"},
        {step: 2, label: "Guarantors"},
        {step: 3, label: "Fuelcard"},
        {step: 4, label: "Direct Debit"},
        {step: 5, label: "Submit"}
    ];

    return (
        <ProvideFormManager formID="business">
            <FormProgress steps={steps}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Switch>
                            <Route path={`${route.path}/account`}>
                                <Account />
                            </Route>
                            <Route path={`${route.path}/guarantors`}>
                                <Guarantors />
                            </Route>
                            <Route path={`${route.path}/fuelcard`}>
                                <Fuelcard />
                            </Route>
                            <Route path={`${route.path}/directdebit`}>
                                <DirectDebit />
                            </Route>
                            <Route path={`${route.path}/submit`}>
                                <Submit />
                            </Route>
                            <Route path={`${route.path}/complete`}>
                                <Complete />
                            </Route>
                            <Route path={`${route.path}`}>
                                <Start />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </ProvideFormManager>
    );
};

export default NewBusinessPage;
