import React, {useCallback, useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {Alert, Button} from "react-bootstrap";
import {Link, useRouteMatch, useHistory} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormButton from "../../../components/forms/FormButton";

const Start = () => {
    const route = useRouteMatch();
    const history = useHistory();
    const {savedFormValues, updateFormStep, setupForm} = useFormManager();
    const [existingApplication, setExistingApplication] = useState(null);
    const [settingUp, setSettingUp] = useState(false);
    const [settingUpError, setSettingUpError] = useState(null);

    const newApplication = useCallback(() => {
        setSettingUp(true);
        setSettingUpError(null);

        setupForm()
            .then(() => {
                history.push(`${route.path}/account`);
            })
            .catch(err => {
                setSettingUpError(true);
                setSettingUp(false);
            });

    }, [route.path, setupForm, history]);

    useEffect(() => {
        updateFormStep(0);
        setExistingApplication(!isEmpty(omit(savedFormValues, 'step')));
    }, [updateFormStep, savedFormValues]);

    return (
        <>
            <div className="section">
                <h1>RD Petroleum Application</h1>
                <h2>Home Heating</h2>
                <p>
                    This application is to be completed in order to obtain an account for Home Heating with RD Petroleum Limited (&quot;RD Petroleum&quot;).
                </p>
                <p>
                    On submission of this form you will be emailed a document to be signed electronically. Your application
                    cannot be processed until this document has been signed.
                </p>
                <p>
                    If you have any questions about this form or the services that RD Petroleum provide, please contact us on <a href="tel:0800 44 00 14">0800 44 00
                    14</a>.
                </p>
            </div>

            {
                settingUpError &&
                    <Alert variant="danger">Error setting up form, please try again later</Alert>
            }

            {
                existingApplication !== null &&
                    existingApplication ?
                        <>
                            <div className="section">
                                <h2>Continue Application</h2>
                                <p>It looks like you've already started an application. Do you want to continue your existing application?</p>
                                <p><Button as={Link} to={`${route.path}/account`} variant="primary">Continue Application</Button></p>
                            </div>
                            <div className="section">
                                <h2>New Application</h2>
                                <p>Clear your existing information and start a new application?</p>
                                <p><FormButton loading={settingUp} variant="primary" onClick={newApplication}>New Application</FormButton></p>
                            </div>
                        </>
                        :
                        <div className="section">
                            <p>
                                <FormButton loading={settingUp} variant="primary" onClick={newApplication}>Start Application</FormButton>
                                <span className="ml-3"><Link to="/" className="text-dark">Cancel</Link></span>
                            </p>
                        </div>
            }

        </>
    );
};

export default Start;
