import React from 'react';
import {useLocation} from 'react-router-dom';

import './styles.scss';

const Error404Page = () => {
    const location = useLocation();
    return (
        <div className="container-fluid">
            <div className="error-container">
                <div className="error-status">
                    404
                </div>
                <div className="error-message">
                    The page <code>{location.pathname}</code> could not be found.
                </div>
            </div>
        </div>
    );

};

export default Error404Page;
